import {
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Customizedinput from "@components/Customizedinput";
import Vieweye from "@components/Vieweye";
import Hideeye from "@components/Hideeye";
import imageset1 from "@images/imageset1.png";
import CommonImage from "@components/CommonImage";
import Customizedbutton from "../components/Customizedbutton";
import {
  emailchecking,
  passwordchecking,
} from "@validations/Commonvalidations";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { androidDelete, androidDeleteOTP } from "../services/Userservices";
import CancelIcon from "@mui/icons-material/Cancel";

const AndroidDeleteAccount = () => {
  const [accountDetails, setAccountDetails] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [passwordHide, setPasswordHide] = useState(false);
  const [openotp, setOpenotp] = useState(false);

  // Updating the input state
  const handleinputchange = (event) => {
    const { name, value } = event.target;
    if (name === "otp" && isNaN(value)) {
      return;
    }
    setAccountDetails((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };
  const closePopup = () => {
    setOpenotp(false);
    setAccountDetails((prev) => ({
      ...prev,
      otp: "",
    }));
  };
  //   otp send function
  const sendOtp = async (event) => {
    event.preventDefault();
    try {
      if (emailchecking(accountDetails.email, "Email ID")) return;
      if (accountDetails.password === "") {
        customEnqueueSnackbar(
          "Oops! You forgot to enter your Password. Please provide it.",
          { variant: "warning" }
        );
        return;
      }
      const { otp, ...payload } = accountDetails;
      const otpRes = await androidDelete(payload);
      if (otpRes?.status) {
        customEnqueueSnackbar(otpRes?.message, { variant: "success" });
        setOpenotp(true);
      } else {
        customEnqueueSnackbar(otpRes?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };
  //   otp verification function
  const verifyOtp = async (event) => {
    event.preventDefault();
    try {
      if (accountDetails?.otp === "") {
        customEnqueueSnackbar(
          "Oops! You forgot to enter your OTP. Please provide it.",
          { variant: "warning" }
        );
        return;
      }
      const { password, ...payload } = accountDetails;
      const otpRes = await androidDeleteOTP(payload);
      if (otpRes?.status) {
        customEnqueueSnackbar(otpRes?.message, { variant: "success" });
        setAccountDetails({
          email: "",
          password: "",
          otp: "",
        });
        setOpenotp(false);
        setPasswordHide(false);
      } else {
        customEnqueueSnackbar(otpRes?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100vw", height: "100vh", position: "relative" }}
      >
        {/* logo */}
        <Grid
          container
          alignSelf={"flex-start"}
          justifyContent={"center"}
          sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        >
          <CommonImage src={imageset1} width={"80px"} alt="Responsive Image" />
        </Grid>
        {/* inputs card */}
        <Grid
          container
          sx={{
            width: "420px",
            maxWidth: "100%",
            margin: "1rem",
            padding: "1rem",
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            borderRadius: "1rem",
            "@media screen and (max-width: 419px)": {
              boxShadow: "none",
              padding: "0rem",
            },
          }}
        >
          <form style={{ width: "100%" }} onSubmit={sendOtp}>
            <Grid item xs={12} container direction={"column"} gap={2}>
              <Typography textAlign={"center"} fontWeight={700} fontSize={20}>
                Delete Account
              </Typography>
              <Grid item>
                <Stack spacing={1}>
                  <Grid item fontWeight={"700"}>
                    <sup style={{ color: "red" }}>*</sup>Email ID
                  </Grid>
                  <Grid item>
                    <Customizedinput
                      size={"medium"}
                      placeholder="Enter here"
                      fullWidth
                      value={accountDetails?.email}
                      onChange={handleinputchange}
                      name="email"
                      type={"email"}
                      decapitalize="true"
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={1}>
                  <Grid item fontWeight={"700"}>
                    <sup style={{ color: "red" }}>*</sup>Password
                  </Grid>
                  <Grid item>
                    <Customizedinput
                      size={"medium"}
                      placeholder="Enter here"
                      fullWidth
                      name="password"
                      decapitalize="true"
                      onChange={handleinputchange}
                      value={accountDetails?.password}
                      type={passwordHide ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setPasswordHide((prev) => !prev)}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordHide ? <Vieweye /> : <Hideeye />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Customizedbutton
                  type={"submit"}
                  data={"Send OTP"}
                  size={"xl"}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      {/* otp verification popup */}
      {openotp && (
        <Dialog
          open={openotp}
          onClose={closePopup}
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: { borderRadius: "1rem", position: "relative" },
          }}
        >
          <CancelIcon
            sx={{
              position: "absolute",
              top: 7,
              right: 7,
              color: "#04BEF8",
              cursor: "pointer",
            }}
            onClick={closePopup}
          />
          <DialogContent>
            <form style={{ width: "100%" }} onSubmit={verifyOtp}>
              <Grid item xs={12} container direction={"column"} gap={2}>
                <Typography textAlign={"center"} fontWeight={700} fontSize={20}>
                  OTP Verification
                </Typography>
                <Grid item>
                  <Stack spacing={1}>
                    <Grid item fontWeight={"700"}>
                      <sup style={{ color: "red" }}>*</sup>OTP
                    </Grid>
                    <Grid item>
                      <Customizedinput
                        size={"medium"}
                        placeholder="Enter here"
                        fullWidth
                        value={accountDetails?.otp}
                        onChange={handleinputchange}
                        name="otp"
                        type={"text"}
                        decapitalize="true"
                      />
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item container justifyContent={"center"}>
                  <Customizedbutton
                    type={"submit"}
                    data={"Verify OTP"}
                    size={"xl"}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AndroidDeleteAccount;
