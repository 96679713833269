import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
import aboutusbg from "@images/aboutus.png";
import pressbg from "@images/pressandnews.png";

export const Termsconditionstext = makeStyles({
  maintext: {
    fontSize: "2rem",
    fontWeight: "700",
  },
  subtext: {
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  text: {
    fontSize: "1rem",
    color: `${style_exports.shade_color}`,
  },
  datetext: {
    color: `${style_exports.shade_color}`,
    fontWeight: "500",
  },
  paragraphstyle: {
    border: "solid 1px #00000010",
    borderRadius: "16px !important",
    "& p": {
      margin: 0,
    },
  },

});
export const Aboutusstyles = makeStyles({
  cardtextstyle:{
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  aspectratiostyle: {
    borderRadius: "1rem",
    aspectRatio: "16/10",
    objectFit: "contain",
    backgroundColor: "rgb(217, 217, 217)",
  },
  blacktextellipsis:{
    textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    wordBreak: "break-all",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  cardaspectratio: {
    width: "100%",
    // height: "100%",
    aspectRatio: 16 / 10,
    borderRadius: "0.5rem",
    overflow: "hidden",
  },
  gridresponsive_aboutus: {
    gap: "5rem",
    "@media screen and (min-width: 900px) and  (max-width:1150px)" : {
      gap: "3.125rem",
    },
    "@media screen and (min-width: 600px) and  (max-width:899px)" : {
      gap: "1rem",
    },
    "@media screen and (min-width: 0px) and  (max-width:599px)" : {
      gap: "0.5rem",
    },
  },
  aboutusbg: {
    backgroundImage: `url(${aboutusbg})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (max-width: 599px)": {
      height: "220px",
    },
  },
  abouttextsection: {
    color: `${style_exports.btn_txtcolor}`,
    fontSize: "1.5rem",
    fontWeight: "400",
  },
  abouttext: {
    color: `${style_exports.shade_color}`,
    fontSize: "3.1875rem",
    textAlign:'justify',
    fontWeight: "400",
    "@media screen and (min-width: 900px) and  (max-width:1150px)" : {
      fontSize: "2.5rem !important",
    },
  },
  imageresponsive_aboutus:{
    width:'100%',
    aspectRatio: 16/16,
    objectFit: "contain",
    borderRadius: "1rem",
    height:'auto',
    "@media screen and (min-width: 100px) and  (max-width:599px)" : {
      width: "100%",
      height: "auto",
    },
  },
  abouttext12: {
    color: `${style_exports.shade_color}`,
    fontSize: "2.1875rem",
    textAlign:'justify',
    fontWeight: "400",
    "@media screen and (min-width: 900px) and  (max-width:1150px)" : {
      fontSize: "1.5rem !important",
    },
    "@media screen and (min-width: 600px) and  (max-width:899px)" : {
      fontSize: "1.2rem !important",
    },
    "@media screen and (min-width: 200px) and  (max-width:599px)" : {
      fontSize: "1.5rem !important",
    },
  },
  aboutbold: {
    fontWeight: "700",
    lineHeight: "1.5rem",
  },
  stylered: {
    backgroundColor: `${style_exports.premium_bg_clr}`,
  },
  stylewhite: {
    backgroundColor: `${style_exports.btn_txtcolor}`,
  },
  redbottom: {
    backgroundColor: `${style_exports.premium_bg_clr}`,
    borderBottomRightRadius: "50px",
  },
  bordertopbottom: {
    backgroundColor: `${style_exports.premium_bg_clr}`,
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
  },
  redtopradius: {
    backgroundColor: `${style_exports.premium_bg_clr}`,
    borderTopLeftRadius: "50px",
    height: "100%",
  },
  whitebottomradius: {
    backgroundColor: `${style_exports.btn_txtcolor}`,
    borderBottomRightRadius: "125px",
    "@media screen and (min-width:100px) and (max-width:599px)": {
      borderBottomRightRadius: "0px",
    },
  },
  shadetext: {
    fontSize: "1rem",
    color: `${style_exports.shade_color}`,
    opacity: "0.5",
  },
  bluetext:{
    color: `${style_exports.btn_bgcolor}`,
    fontSize: "1.5rem",
    fontWeight:'700',
  },
  mainblacktext:{
    color: `${style_exports.btn_bg_color}`,
    fontSize: "1.5rem",
    fontWeight:'700',
  },
  blacktext:{
    color: `${style_exports.btn_bg_color}`,
    fontWeight:'700',
    marginBottom:'1rem'
  },
  shadetextabout:{
    color:`${style_exports.shade_text_color}`
  },
  pressbgimg:{
    backgroundImage: `url(${pressbg})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
 
  },
  teamMemberImage: {
    width: "100%",
    aspectRatio: 16 / 16,
    objectFit: "contain",
    borderRadius: "1rem",
    background: style_exports?.grey_color,
  },
});
