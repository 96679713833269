import React from "react";

const Vieweye = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.3">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 8.26074C11.0054 8.26074 10.0516 8.65583 9.34835 9.35909C8.64509 10.0624 8.25 11.0162 8.25 12.0107C8.25 13.0053 8.64509 13.9591 9.34835 14.6624C10.0516 15.3657 11.0054 15.7607 12 15.7607C12.9946 15.7607 13.9484 15.3657 14.6517 14.6624C15.3549 13.9591 15.75 13.0053 15.75 12.0107C15.75 11.0162 15.3549 10.0624 14.6517 9.35909C13.9484 8.65583 12.9946 8.26074 12 8.26074ZM9.75 12.0107C9.75 11.414 9.98705 10.8417 10.409 10.4198C10.831 9.9978 11.4033 9.76074 12 9.76074C12.5967 9.76074 13.169 9.9978 13.591 10.4198C14.0129 10.8417 14.25 11.414 14.25 12.0107C14.25 12.6075 14.0129 13.1798 13.591 13.6017C13.169 14.0237 12.5967 14.2607 12 14.2607C11.4033 14.2607 10.831 14.0237 10.409 13.6017C9.98705 13.1798 9.75 12.6075 9.75 12.0107Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 3.26074C7.486 3.26074 4.445 5.96474 2.68 8.25774L2.649 8.29874C2.249 8.81774 1.882 9.29474 1.633 9.85874C1.366 10.4637 1.25 11.1227 1.25 12.0107C1.25 12.8987 1.366 13.5577 1.633 14.1627C1.883 14.7267 2.25 15.2047 2.649 15.7227L2.681 15.7637C4.445 18.0567 7.486 20.7607 12 20.7607C16.514 20.7607 19.555 18.0567 21.32 15.7637L21.351 15.7227C21.751 15.2047 22.118 14.7267 22.367 14.1627C22.634 13.5577 22.75 12.8987 22.75 12.0107C22.75 11.1227 22.634 10.4637 22.367 9.85874C22.117 9.29474 21.75 8.81774 21.351 8.29874L21.319 8.25774C19.555 5.96474 16.514 3.26074 12 3.26074ZM3.87 9.17274C5.498 7.05574 8.15 4.76074 12 4.76074C15.85 4.76074 18.501 7.05574 20.13 9.17274C20.57 9.74274 20.826 10.0827 20.995 10.4647C21.153 10.8227 21.25 11.2597 21.25 12.0107C21.25 12.7617 21.153 13.1987 20.995 13.5567C20.826 13.9387 20.569 14.2787 20.131 14.8487C18.5 16.9657 15.85 19.2607 12 19.2607C8.15 19.2607 5.499 16.9657 3.87 14.8487C3.43 14.2787 3.174 13.9387 3.005 13.5567C2.847 13.1987 2.75 12.7617 2.75 12.0107C2.75 11.2597 2.847 10.8227 3.005 10.4647C3.174 10.0827 3.432 9.74274 3.87 9.17274Z"
            fill="black"
          />
        </g>
      </svg>
    </>
  );
};

export default Vieweye;
