/** @format */

import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Customizedselect = ({
  value,
  size,
  onChange,
  options,
  type,
  name,
  display_name,
  dynamic_val,
  bg_color,
  placeholder,
  sub_type,
  sub_display_name,
  ...otherprops
}) => {
  /**
   * value-selecte input values
   * onChange->callback
   * options->input to select menu items
   * type:{
   *  1->array of values
   * 2->array of objects
   * }
   * display_name->
   * if otions is array of objects then we need to give display name
   */
  return (
    <FormControl
      variant="outlined"
      fullWidth
      size={size === undefined ? "medium" : size}
    >
      {/* {console.log(value,"value")} */}
      <Select
        defaultValue={"Select"}
        variant="outlined"
        IconComponent={KeyboardArrowDownIcon}
        onChange={(event) => onChange(event.target.value, dynamic_val, name)}
        fullWidth
        value={value}
        {...otherprops}
        sx={{
          background: bg_color ? bg_color : "#F2F2F2",
          border: "none",
          // height: `3.5rem`,
          borderRadius: "0.5rem",
          "& .MuiSvgIcon-root": {
            color: "#04BEF8",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        displayEmpty
        renderValue={(selected) => {
          if (otherprops?.multiple) {
            return selected === "Select" || selected.length === 0
              ? placeholder
              : [selected.map((item) => item[display_name])].join(", ") ?? [];
          } else {
            if (
              (selected?.length === 0 || selected === "Select") &&
              (value === undefined || value === "")
            ) {
              return (
                <span style={{ color: "#333333", opacity: "0.5" }}>
                  {placeholder !== undefined ? placeholder : "Select"}{" "}
                </span>
              ); // Customize placeholder text and style
            } else {
              return type === 1 ? value : value;
            }
          }
        }}
      >
        {options?.length > 0 &&
          options.map((option) => (
            <MenuItem key={option.value} value={option}>
              {type === 1 ? option : option[display_name]}{" "}
              {sub_type === 1 ? `(${option[sub_display_name]})` : null}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default Customizedselect;
