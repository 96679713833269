/** @format */

import { TextField } from "@mui/material";
import React, { useRef } from "react";
import { signup_styles } from "@styles/signup_styles";
import { style_exports } from "@exports/style_exports";
import { customEnqueueSnackbar } from "./constants/Jsxconstants";

const Customizedinput = (props) => {
  const { styles, onChange, decapitalize, startSymbol, maxWordLength, ...otherprops } = props;
  const inputstyles = signup_styles();
  const InputRef = useRef(null);

  /** Handle key down to prevent double spaces */
  const handleKeyDown = (event) => {
    const { key } = event;
    const { selectionStart, selectionEnd } = event.target;

    if (key === " " && InputRef.current) {
      const value = InputRef.current.value;

      const beforeChar = selectionStart > 0 ? value[selectionStart - 1] : null;
      const afterChar =
        selectionEnd < value.length ? value[selectionEnd] : null;

      if (beforeChar === " " || afterChar === " ") {
        event.preventDefault();
      }
    }
  };

  /**changing the First letter of the input to capital only in customized input
   *  which reflects in all other components */
  const handleinputchange = (event) => {
    let newValue = event.target.value ?? "";
    if (!decapitalize) {
      newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
    }
    const cursorPosition = InputRef.current.selectionStart;
    const words = newValue.split(/\s+/);
    if (maxWordLength && words.length >= maxWordLength) {
      customEnqueueSnackbar(`This field is limited to ${maxWordLength} words`, {
        variant: "warning",
      });
      return;
    }
    // Pass the modified value to the onChange handler
    if (props.onChange) {
      props.onChange({
        ...event,
        target: { ...event.target, value: newValue, name: props?.name ?? "" },
      });
    }
    // Restore the cursor position after updating the value
    // setTimeout(() => {
    //   if (InputRef.current && cursorPosition === 1) {
    //     InputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    //   }
    // }, 0);
    if (!startSymbol) {
      setTimeout(() => {
        if (InputRef.current) {
          InputRef.current.selectionStart = cursorPosition;
          InputRef.current.selectionEnd = cursorPosition;
        }
      }, 0);
    }
  };

  return (
    <>
      <TextField
        onKeyDown={handleKeyDown}
        className={inputstyles.inputstyle}
        inputRef={InputRef}
        sx={{
          // background:`${style_exports.grey_color}!important`,
          "& .MuiOutlinedInput-root": {
            background: `${
              props.bg_color === undefined || !props.bg_color
                ? style_exports.grey_color
                : props.bg_color
            }!important`,
            borderRadius: `0.5rem`,
            // height: `3.5rem`,
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": `0 0 0 30px ${
              props.bg_color === undefined || !props.bg_color
                ? style_exports.grey_color
                : props.bg_color
            } inset !important`,
            background: "transparent !important",
          },
          // borderRadius:`${input_radius}`,
          border: "none!important",
          ...styles,
        }}
        size="medium"
        onChange={handleinputchange} //handleinputchange
        {...otherprops}
      />
    </>
  );
};
//How do i change the first letter of this input field to be capital
export default Customizedinput;
