import { Card, CardHeader, CardContent, Grid, Skeleton } from "@mui/material";
import { competitioncardStyles } from "@styles/CardStyles";

export const CompetitionCardSkeleton = () => {
  const styles = competitioncardStyles();

  return (
    <Card className={styles.cardContainer}>
      <CardHeader
        className={styles.cardHeader}
        action={
          <Skeleton variant="circular" width={25} height={25} />
        }
      />
      <CardContent className={styles.cardBody}>
        <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} gap={1}>
          <Skeleton variant="rectangular" width={100} height={100} />
          <Skeleton variant="text" width={150} height={30} />
        </Grid>
      </CardContent>
    </Card>
  );
};
