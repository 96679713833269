export const Envexports = {
  baseurl: process.env.REACT_APP_BACKEND_URL,
  googleauthkey: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  defaultauthkey: process.env.REACT_APP_DEFAULT_AUTHKEY,
  recaptchasitekey:process.env.REACT_APP_RECAPTCHASITE_KEY,
  encryptionkey: process.env.REACT_APP_ENCRYPTION_KEY,
  flagsbaseurl: process.env.REACT_APP_FLAGSBASEURL,
  firebase_apikey: process.env.REACT_APP_FIREBASE_APIKEY,
  firebase_authdomain:process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  firebase_projectid: process.env.REACT_APP_FIREBASE_PROJECTID,
  firebase_storagebucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  firebase_messagesenderid: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  firebase_appid: process.env.REACT_APP_FIREBASE_APPID,
  firebase_measurementid: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  // ////////////////////////////////////////////////////
  // firebase_apikey: "AIzaSyA4frlImFQV8oduPx0xVJfoQu3GioMeNK4",//process.env.REACT_APP_FIREBASE_APIKEY,
  // firebase_authdomain:"yourmoca-6313f.firebaseapp.com", //process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  // firebase_projectid: "yourmoca-6313f",//process.env.REACT_APP_FIREBASE_PROJECTID,
  // firebase_storagebucket: "yourmoca-6313f.appspot.com", //process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  // firebase_messagesenderid: "362890070181", //process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  // firebase_appid: "1:362890070181:web:c84b149aacf8288748e54c",//process.env.REACT_APP_FIREBASE_APPID,
  // firebase_measurementid: "G-REP5NZ9MCW",//process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
