import React from "react";

const FavoriteIcon = (props) => {
  return (
    <>
      <svg
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M68.5942 15.1747C66.913 13.4927 64.9168 12.1584 62.7198 11.2481C60.5227 10.3377 58.1679 9.86914 55.7897 9.86914C53.4115 9.86914 51.0566 10.3377 48.8595 11.2481C46.6625 12.1584 44.6663 13.4927 42.9851 15.1747L39.4959 18.6639L36.0067 15.1747C32.6108 11.7787 28.0048 9.8709 23.2022 9.8709C18.3995 9.8709 13.7936 11.7787 10.3976 15.1747C7.0016 18.5707 5.09375 23.1767 5.09375 27.9793C5.09375 32.782 7.0016 37.3879 10.3976 40.7839L13.8867 44.2731L39.4959 69.8822L65.1051 44.2731L68.5942 40.7839C70.2763 39.1027 71.6106 37.1065 72.5209 34.9094C73.4313 32.7124 73.8998 30.3575 73.8998 27.9793C73.8998 25.6011 73.4313 23.2462 72.5209 21.0492C71.6106 18.8521 70.2763 16.856 68.5942 15.1747Z"
          stroke="#484848"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default FavoriteIcon;
