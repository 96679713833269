import React from "react";

const ProjectListIcon = (props) => {
  return (
    <>
      <svg
        width="80"
        height="79"
        viewBox="0 0 80 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M28.8781 23.042C28.2233 23.042 27.5954 23.3021 27.1324 23.7651C26.6694 24.2281 26.4093 24.856 26.4093 25.5107C26.4093 26.1655 26.6694 26.7934 27.1324 27.2564C27.5954 27.7194 28.2233 27.9795 28.8781 27.9795H53.5656C54.2203 27.9795 54.8483 27.7194 55.3113 27.2564C55.7742 26.7934 56.0343 26.1655 56.0343 25.5107C56.0343 24.856 55.7742 24.2281 55.3113 23.7651C54.8483 23.3021 54.2203 23.042 53.5656 23.042H28.8781ZM23.1177 38.6774C23.1177 38.0227 23.3778 37.3947 23.8408 36.9317C24.3037 36.4688 24.9317 36.2087 25.5864 36.2087H46.9823C47.637 36.2087 48.2649 36.4688 48.7279 36.9317C49.1909 37.3947 49.451 38.0227 49.451 38.6774C49.451 39.3322 49.1909 39.9601 48.7279 40.4231C48.2649 40.8861 47.637 41.1462 46.9823 41.1462H25.5864C24.9317 41.1462 24.3037 40.8861 23.8408 40.4231C23.3778 39.9601 23.1177 39.3322 23.1177 38.6774ZM32.1698 49.3753C31.515 49.3753 30.8871 49.6354 30.4241 50.0984C29.9611 50.5614 29.701 51.1893 29.701 51.8441C29.701 52.4988 29.9611 53.1268 30.4241 53.5897C30.8871 54.0527 31.515 54.3128 32.1698 54.3128H53.5656C54.2203 54.3128 54.8483 54.0527 55.3113 53.5897C55.7742 53.1268 56.0343 52.4988 56.0343 51.8441C56.0343 51.1893 55.7742 50.5614 55.3113 50.0984C54.8483 49.6354 54.2203 49.3753 53.5656 49.3753H32.1698Z"
          fill="#484848"
        />
        <path
          d="M6.65967 12.3434C6.65967 9.16367 9.24033 6.58301 12.4201 6.58301H66.7326C69.9123 6.58301 72.493 9.16367 72.493 12.3434V66.6559C72.493 68.1837 71.8861 69.6489 70.8058 70.7292C69.7255 71.8094 68.2603 72.4163 66.7326 72.4163H12.4201C10.8923 72.4163 9.42714 71.8094 8.34686 70.7292C7.26657 69.6489 6.65967 68.1837 6.65967 66.6559V12.3434ZM12.4201 11.5205C12.2018 11.5205 11.9925 11.6072 11.8382 11.7615C11.6839 11.9159 11.5972 12.1252 11.5972 12.3434V66.6559C11.5972 67.1102 11.9658 67.4788 12.4201 67.4788H66.7326C66.9508 67.4788 67.1601 67.3921 67.3145 67.2378C67.4688 67.0835 67.5555 66.8742 67.5555 66.6559V12.3434C67.5555 12.1252 67.4688 11.9159 67.3145 11.7615C67.1601 11.6072 66.9508 11.5205 66.7326 11.5205H12.4201Z"
          fill="#484848"
        />
      </svg>
    </>
  );
};

export default ProjectListIcon;
