import React from "react";
import { Grid, Skeleton, Avatar, Hidden, Rating } from "@mui/material";

const ReviewsSkeleton = () => {
  return (
    <Grid item container spacing={2}>
      <Grid item container xl={3.5} md={3.5} sm={3.5} xs={12} gap={0.5}>
        <Grid item xl={"auto"} md={"auto"} sm={"auto"} xs={"auto"}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </Grid>
        {/* Responsive review */}
        <Hidden only={["md", "sm", "lg", "xl"]}>
          <Grid item container xs={10.5} spacing={1}>
            <Grid item xs={5.5}>
              <Skeleton variant="text" width={120} height={24} />
            </Grid>
            <Grid item xs={6.5} textAlign={"end"}>
              <Skeleton variant="text" width={100} height={24} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rectangular" width={80} height={24} />
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <Skeleton variant="text" width={60} height={20} />
            </Grid>
          </Grid>
        </Hidden>
        {/* Responsive review end here */}
        <Grid
          item
          xl
          md
          sm
          xs
          sx={{
            display: { md: "block", sm: "block", xs: "none" },
          }}
          container
          direction={"column"}
          gap={0.5}
        >
          <Grid item>
            <Skeleton variant="text" width={120} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={80} height={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={150} height={20} />
          </Grid>
          <Grid item>
            <Skeleton variant="text" width={60} height={20} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={8.5} md={8.5} sm={8.5} xs={12}>
        <Skeleton variant="text" width={"100%"} height={80} />
      </Grid>
    </Grid>
  );
};

export default ReviewsSkeleton;
