import axios from "@services/Yourmocaclient";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
const Userapiservice = async (endpoint, data, method, contentType) => {
  const config = {
    method: method, // HTTP method
    url: `/${endpoint}`,
    data: method === "GET" ? {} : data, // Request data
    headers: {
      "Content-Type": contentType,
    },
  };

  try {
    const res = await axios(config);
    return res?.data;
  } catch (err) {
    // console.log(err);
    if(err?.response === undefined){
      console.log(err,"---coming from userapiservice");
      // customEnqueueSnackbar("Internal server error", {variant: "error"})
    }
  }
};
export default Userapiservice;
