import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Termsconditionstext } from "@styles/Terms&conditions";
import Privacytermshoc from "./Hocs/Privacytermshoc";
import { scrollTop } from "../helpers/Helperfunctions";

const Termsconditions = ({ data }) => {
  const classes = Termsconditionstext();
  useEffect(() => {
    scrollTop()
  }, []);
  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginBottom: "2rem" }}>
        {/* <Grid container direction={"column"}  spacing={3}>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.maintext}>
                YourMoca's Terms of Service
              </span>
            </Grid>
            <Grid item>
              <span className={classes.datetext}>
                Last update: July 23, 2023
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Key Terms</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                hendrerit tempus metus in varius. Mauris eu volutpat mauris.
                Donec augue sem, fermentum eu laoreet a, pharetra nec quam.
                Mauris a aliquam nulla, vel facilisis nisl. Pellentesque sit
                amet quam bibendum, tempus lectus ut, laoreet metus. Donec
                malesuada mauris quis erat auctor, sit amet fermentum odio
                eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>
                Overview (Main terms, in a nutshell)
              </span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                hendrerit tempus metus in varius. Mauris eu volutpat mauris.
                Donec augue sem, fermentum eu laoreet a, pharetra nec quam.
                Mauris a aliquam nulla, vel facilisis nisl. Pellentesque sit
                amet quam bibendum, tempus lectus ut, laoreet metus. Donec
                malesuada mauris quis erat auctor, sit amet fermentum odio
                eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.maintext}>Crew & Vendors</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                hendrerit tempus metus in varius. Mauris eu volutpat mauris.
                Donec augue sem, fermentum eu laoreet a, pharetra nec quam.
                Mauris a aliquam nulla, vel facilisis nisl. Pellentesque sit
                amet quam bibendum, tempus lectus ut, laoreet metus. Donec
                malesuada mauris quis erat auctor, sit amet fermentum odio
                eleifend.Llaoreet a, pharetra nec quam. Mauris a aliquam nulla,
                vel facilisis nisl. Pellentesque sit amet quam bibendum, tempus
                lectus ut, laoreet metus. Donec malesuada mauris quis erat
                auctor, sit amet fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Basics</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Consectetur adipiscing elit. Donec hendrerit tempus metus in
                varius. Mauris eu volutpat mauris. Donec augue sem, fermentum eu
                laoreet a, pharetra nec quam. Mauris a aliquam nulla, vel
                facilisis nisl. Pellentesque sit amet quam bibendum, tempus
                lectus ut, laoreet metus. Donec malesuada mauris quis erat
                auctor, sit amet fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Levels</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                hendrerit tempus metus in varius. Mauris eu volutpat mauris.
                Donec augue sem, fermentum eu laoreet.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Features</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Hempus metus in varius. Mauris eu volutpat mauris. Donec augue
                sem, fermentum eu laoreet a, pharetra nec quam. Mauris a aliquam
                nulla, vel facilisis nisl. Pellentesque sit amet quam bibendum.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Reviews</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Nec quam. Mauris a aliquam nulla, vel facilisis nisl.
                Pellentesque sit amet quam bibendum, tempus lectus ut, laoreet
                metus. Donec malesuada mauris quis erat auctor, sit amet
                fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>
                Disputes and Cancellations
              </span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Pharetra nec quam. Mauris a aliquam nulla, vel facilisis nisl.
                Pellentesque sit amet quam bibendum, tempus lectus ut, laoreet
                metus. Donec malesuada mauris quis erat auctor, sit amet
                fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.maintext}>
                User Conduct & Protection
              </span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Llaoreet a, pharetra nec quam. Mauris a aliquam nulla, vel
                facilisis nisl. Pellentesque sit amet quam bibendum, tempus
                lectus ut, laoreet metus. Donec malesuada mauris quis erat
                auctor, sit amet fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Basics</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Consectetur adipiscing elit. Donec hendrerit tempus metus in
                varius. Mauris eu volutpat mauris. Donec augue sem, fermentum eu
                laoreet a, pharetra nec quam. Mauris a aliquam nulla, vel
                facilisis nisl. Pellentesque sit amet quam bibendum, tempus
                lectus ut, laoreet metus. Donec malesuada mauris quis erat
                auctor, sit amet fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Reporting Violations</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                hendrerit tempus metus in varius. Mauris eu volutpat mauris.
                Donec augue sem, fermentum eu laoreet.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>General Terms</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Hempus metus in varius. Mauris eu volutpat mauris. Donec augue
                sem, fermentum eu laoreet a, pharetra nec quam. Mauris a aliquam
                nulla, vel facilisis nisl. Pellentesque sit amet quam bibendum.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Ownership</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Nec quam. Mauris a aliquam nulla, vel facilisis nisl.
                Pellentesque sit amet quam bibendum, tempus lectus ut, laoreet
                metus. Donec malesuada mauris quis erat auctor, sit amet
                fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={"column"}>
            <Grid item>
              <span className={classes.subtext}>Limitation on Liability</span>
            </Grid>
            <Grid item>
              <span className={classes.text}>
                Pharetra nec quam. Mauris a aliquam nulla, vel facilisis nisl.
                Pellentesque sit amet quam bibendum, tempus lectus ut, laoreet
                metus. Donec malesuada mauris quis erat auctor, sit amet
                fermentum odio eleifend.
              </span>
            </Grid>
          </Grid>
        </Grid> */}
        {data && Object.keys(data).length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        )}{" "}
      </Container>
    </>
  );
};
// Enhance MyComponent with the Privacytermshoc HOC
const TermsconditionswithData = Privacytermshoc(Termsconditions);
export default TermsconditionswithData;
