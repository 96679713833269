import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React from "react";
import CommonImage from "@components/CommonImage";
import tick from "@images/tick.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Portfolio_Styles } from "@styles/Dashboardstyles";
import CancelIcon from "@mui/icons-material/Cancel";
const Sucessmodal = (props) => {
  const { open, close, message, buttonText } = props;
  const JSX_styles = Portfolio_Styles();

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth={true}
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          m: 0,
          width: 350,
          maxWidth: "100%",
          "::-webkit-scrollbar": { display: "none" },
          position: "fixed",
          top: "25%",
          borderRadius: "25px",
          background: "#FFFFFF",
          backdropFilter: "blur(15px)",
          "@media screen and (min-width: 200px) and (max-width: 550px)": {
            width: "90%",
            maxWidth: "290px",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container position={"relative"}>
          <Grid
            container
            item
            xs={11}
            sm={11}
            md={11}
            mt={2}
            justifyContent={"center"}
            alignItems={"center"}
            fontWeight={700}
          >
            <CommonImage
              style={{ paddingLeft: "2rem", width: "60px" }}
              src={tick}
              alt="tickicon"
            />
          </Grid>
          {/* <HighlightOffIcon
            sx={{ color: "rgba(0, 0, 0, 0.6)", position:'absolute',top:'0px',right:'-10px' }}
            onClick={close}
          /> */}
          <CancelIcon
            sx={{
              position: "absolute",
              top: -5,
              right: -15,
              color: "#04BEF8",
            }}
            // className={JSX_styles.closeiconstyle}
            onClick={close}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          gap={2}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid
            item
            mb={1}
            textAlign={"center"}
            fontSize={"20px"}
            fontWeight={700}
          >
            {message?.title}
          </Grid>
          <Grid item fontSize={"14px"}>
            {message?.html}
          </Grid>
          <Grid item>{message?.button_text}</Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Sucessmodal;
