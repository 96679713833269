/** @format */

import Userapiservice from "@services/Userapiservice";
const registerapi = async (payload) => {
  const res = await Userapiservice(
    "signUp",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const loginapi = async (payload) => {
  const res = await Userapiservice(
    "login",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const sociallogins = async (payload) => {
  const res = await Userapiservice(
    "socialLogin",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const resetpwd = async (payload) => {
  const res = await Userapiservice(
    "resetPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const profile_creation = async (payload) => {
  const res = await Userapiservice(
    "createProfile",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const create_profiledata = async (payload) => {
  const res = await Userapiservice(
    "getCreateProfileDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const edit_castingcall_req = async (payload) => {
  const res = await Userapiservice(
    "editCastingRequirements",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getcastingcall = async (payload) => {
  const res = await Userapiservice(
    "getCastingCallById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const apply_castingcall = async (payload) => {
  const res = await Userapiservice(
    "applyCastingOrCompetitions",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const resendtotp = async (payload) => {
  const res = await Userapiservice(
    "resendOtpOrEmail",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const favorite_list = async (payload) => {
  const res = await Userapiservice(
    "getfavoritesDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const pricingplans_dropdown = async (payload) => {
  const res = await Userapiservice(
    "getDropdownForPricingPlan",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getpackagesOtherProfiles = async (payload) => {
  const res = await Userapiservice(
    "getpackagesOtherProfiles",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const upload_coverimage = async (payload) => {
  const res = await Userapiservice(
    "coverImage",
    payload,
    "POST",
    "multipart/formdata"
  );
  return res;
};
const delete_services = async (payload) => {
  const res = await Userapiservice(
    "userDeleteByIds",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const makeservicepublic = async (payload) => {
  const res = await Userapiservice(
    "makePublic",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const application_status = async (payload) => {
  const res = await Userapiservice(
    "getapplicationStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const add_remove_favorites = async (payload) => {
  const res = Userapiservice(
    "addOrRemoveFavorites",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_user_Data = async (payload) => {
  const res = await Userapiservice(
    "getUserDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const complete_profile = async (payload) => {
  const res = await Userapiservice(
    "completeProfile",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const get_categories = async (payload) => {
  const res = await Userapiservice(
    "getAllCategories",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_subcategories = async (payload) => {
  const { pay_load, sel_category, urlparam } = payload;
  const res = await Userapiservice(
    `subcategories/${sel_category ?? urlparam}`,
    pay_load,
    "POST",
    "application/json"
  );
  return res;
};
const gettingAllSubCategories = async () => {
  const res = await Userapiservice(
    "allSubCategoryList",
    {},
    "POST",
    "application/json"
  );
  return res;
};
const get_user_notifications = async (payload) => {
  const res = await Userapiservice(
    "getNotifications",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getAndUpdateNotifications = async (payload) => {
  const res = await Userapiservice(
    "notificationsettings",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const getAdminNotifications = async (payload) => {
  const res = await Userapiservice(
    "getAdminNotifications",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getestimationlist = async (payload) => {
  const res = Userapiservice(
    "getMyEstimationList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const project_estimations_list = async (payload) => {
  const res = Userapiservice(
    "getProjectEstimationsById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getEstimation_overview = async (payload) => {
  const res = Userapiservice(
    "getProjectEstimationOverview",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_estimation_project = async (payload) => {
  const res = Userapiservice("addProject", payload, "POST", "application/json");
  return res;
};
const getdropdowns_list = async (payload) => {
  const res = Userapiservice(
    "getDropdownList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addeditevent = async (payload) => {
  const res = await Userapiservice(
    "addOrEditeventManagement",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addeditmess = async (payload) => {
  const res = await Userapiservice(
    "addOrEditMess",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addedit_projects = async (payload) => {
  const res = await Userapiservice(
    "addOrEditProjectsList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addedit_transportation = async (payload) => {
  const res = await Userapiservice(
    "addOrEditTransportation",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addedit_location = async (payload) => {
  const res = await Userapiservice(
    "addOrEditLocation",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const edit_mobile = async (payload) => {
  const res = await Userapiservice(
    "editMobileNumber",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_subscriptionplans = async (payload) => {
  const res = await Userapiservice(
    "getSubscriptions",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_equiments_brands_list = async (payload) => {
  const res = Userapiservice(
    "getEquipmentBrands",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getEstimation_projectnames = async (payload) => {
  const res = Userapiservice(
    "getProjectNames",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const add_memberto_estimationlist = async (payload) => {
  const res = Userapiservice(
    "addMemberToEstimationList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const send_intrest_status = async (payload) => {
  const res = await Userapiservice(
    "sendIntrestStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_estimation_status = async (payload) => {
  const res = Userapiservice(
    "updateIntrestStatus",
    payload,
    "PATCH",
    "application/json"
  );
  return res;
};
const getmeetingslist = async (payload) => {
  const res = await Userapiservice(
    "getMeetingPlanList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const sendmeetingplan = async (payload) => {
  const res = await Userapiservice(
    "sendMeetingPlan",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const delete_estimations = async (payload) => {
  const res = await Userapiservice(
    "deleteEstimations",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_duration = async (payload) => {
  const res = await Userapiservice(
    "updateEstimationDuration",
    payload,
    "PATCH",
    "application/json"
  );
  return res;
};
const Myprojects = async (payload) => {
  const res = await Userapiservice(
    "getMyProjects",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addedit_caravan = async (payload) => {
  const res = await Userapiservice(
    "addOrEditCaravan",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
// Krishna adding
const MyprojectDetails = async (payload) => {
  const res = await Userapiservice(
    "getDetailedEstimationServices",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const projectReviewsAndRatings = async (payload) => {
  const res = await Userapiservice(
    "addServiceRatingAndReview",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const projectCompleteRemove = async (payload) => {
  const res = await Userapiservice(
    "updateProjectStatus",
    payload,
    "PATCH",
    "application/json"
  );
  return res;
};
const myRatings = async (payload) => {
  const res = await Userapiservice(
    "getMyRatings",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const myReviews = async (payload) => {
  const res = await Userapiservice(
    "getMyReviews",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const givenRating = async (payload) => {
  const res = await Userapiservice(
    "getGivenRatings",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const myRequests = async (payload) => {
  const res = await Userapiservice(
    "getMyRequests",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const myRequestsOnHold = async (payload) => {
  const res = await Userapiservice(
    "makeHold",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const workById = async (payload) => {
  const res = await Userapiservice(
    "getWorkRequestById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const send_message_pushes = async (payload) => {
  const res = await Userapiservice(
    "sendPushNotificationToUsers",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_estimation_price = async (payload) => {
  const res = await Userapiservice(
    "updateEstimationRateInfo",
    payload,
    "PATCH",
    "application/json"
  );
  return res;
};
const getPopularProfiles = async (payload) => {
  const res = await Userapiservice(
    "getPopularProfiles",
    payload ?? {},
    "POST",
    "application/json"
  );
  return res;
};
const getPopularSearch = async () => {
  const res = await Userapiservice(
    "getPopularSearch",
    {},
    "POST",
    "application/json"
  );
  return res;
};
const updateMeetingStatus = async (payload) => {
  const res = await Userapiservice(
    "updateMeetingStatus",
    payload,
    "PATCH",
    "application/json"
  );
  return res;
};
const getDashboardDetails = async (payload) => {
  const res = await Userapiservice(
    "getDashboardDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_subcategory_profiles = async (payload) => {
  const res = await Userapiservice(
    "getProfiles",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_super_subcategories = async (payload) => {
  const res = await Userapiservice(
    "getSuperSubCategories",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_estimation_details = async (payload) => {
  const res = await Userapiservice(
    "getSendQuoteDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const search_suggestions = async (payload) => {
  const res = await Userapiservice(
    "searchSuggestions",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const deleteWorkPost = async (payload) => {
  const res = await Userapiservice(
    "deleteWorkOrCasting",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_deactivation_status = async (payload) => {
  const res = await Userapiservice(
    "updateAccountStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_twofactor_authentication = async (payload) => {
  const res = await Userapiservice(
    "updateTwoFactorStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_password = async (payload) => {
  const res = await Userapiservice(
    "changePassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_create_album = async (payload) => {
  const res = await Userapiservice(
    "updateOrCreateAlbum",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_emailverification = async (payload) => {
  const res = await Userapiservice(
    "checkEmailVerificationStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const account_restoration = async (payload) => {
  const res = await Userapiservice(
    "sendVerificationsToRestore",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const otp_resend = async (paylod) => {
  const res = await Userapiservice(
    "resendOtpOrEmail",
    paylod,
    "POST",
    "application/json"
  );
  return res;
};

const pricing_plan_creation = async (payload) => {
  const res = await Userapiservice(
    "addOrEditPricingPlan",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const searchworks = async (payload) => {
  const res = await Userapiservice(
    "searchWork",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const add_edit_postrequest = async (payload) => {
  const res = await Userapiservice(
    "addOrEditPostWork",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const forgot_password = async (payload) => {
  const res = await Userapiservice(
    "forgotPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const edit_email = async (payload) => {
  const res = await Userapiservice(
    "editEmail",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const verify_editmail_otp = async (payload) => {
  const res = await Userapiservice(
    "verifyEmailOtp",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const retrieve_portfolio_details = async (payload) => {
  const res = await Userapiservice(
    "getUserPortfolioDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const visitedUsers = async (payload) => {
  const res = await Userapiservice(
    "getProfileVisits",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const favouritedUsers = async (payload) => {
  const res = await Userapiservice(
    "getProfileFavorited",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const homesearch_profiles_services = async (payload) => {
  const res = await Userapiservice(
    "searchProfilesOrServices",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const guestUserPlans = async (payload) => {
  const res = await Userapiservice(
    "guestUserSubscriptions",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const contactUsPost = async (payload) => {
  const res = await Userapiservice(
    "contactUs",
    payload,
    "POST",
    "multipart/formdata"
  );
  return res;
};
const upgradesubscriptionplan = async (payload) => {
  const res = await Userapiservice(
    "updateSubscriptionDetails",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_recentsearches = async (payload) => {
  const res = await Userapiservice(
    "getRecentSearch",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const clear_recent_search = async (payload) => {
  const res = await Userapiservice(
    "clearRecentSearch",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_order = async (payload) => {
  const res = await Userapiservice(
    "orders",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const verify_signature = async (payload) => {
  const res = await Userapiservice(
    "verifySignature",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const checking_userexistence = async (payload) => {
  const res = await Userapiservice(
    "isUserExistsInProject",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
/**
 *
 * @param {*} userId string // user is
 * @param {*} type int // 1 for user, 2 for admin
 * @returns
 */
const userLogout = async (userId) => {
  const payload = {
    userId,
    platformType: 3,
  };
  const res = await Userapiservice(
    "logout",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const androidDelete = async (payload) => {
  const res = await Userapiservice(
    "androidDeleteAccount",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

const androidDeleteOTP = async (payload) => {
  const res = await Userapiservice(
    "otpVerificationForDeleteAccount",
    payload,
    "POST",
    "application/json"
  );
  return res;
};

export {
  registerapi,
  loginapi,
  resetpwd,
  sociallogins,
  profile_creation,
  create_profiledata,
  edit_castingcall_req,
  getcastingcall,
  apply_castingcall,
  resendtotp,
  favorite_list,
  pricingplans_dropdown,
  getpackagesOtherProfiles,
  add_remove_favorites,
  get_user_Data,
  get_categories,
  get_subcategories,
  gettingAllSubCategories,
  get_user_notifications,
  getAndUpdateNotifications,
  getestimationlist,
  project_estimations_list,
  getEstimation_overview,
  create_estimation_project,
  getdropdowns_list,
  getEstimation_projectnames,
  add_memberto_estimationlist,
  update_estimation_status,
  delete_estimations,
  Myprojects,
  MyprojectDetails,
  getmeetingslist,
  sendmeetingplan,
  projectReviewsAndRatings,
  update_duration,
  projectCompleteRemove,
  myRatings,
  myReviews,
  givenRating,
  myRequests,
  myRequestsOnHold,
  update_estimation_price,
  workById,
  getPopularProfiles,
  getPopularSearch,
  send_intrest_status,
  updateMeetingStatus,
  getDashboardDetails,
  get_equiments_brands_list,
  get_subcategory_profiles,
  get_super_subcategories,
  get_estimation_details,
  search_suggestions,
  deleteWorkPost,
  update_deactivation_status,
  update_twofactor_authentication,
  update_password,
  update_create_album,
  update_emailverification,
  account_restoration,
  otp_resend,
  pricing_plan_creation,
  searchworks,
  add_edit_postrequest,
  forgot_password,
  edit_email,
  verify_editmail_otp,
  retrieve_portfolio_details,
  addedit_caravan,
  addeditevent,
  addeditmess,
  addedit_projects,
  addedit_transportation,
  addedit_location,
  edit_mobile,
  get_subscriptionplans,
  upload_coverimage,
  application_status,
  complete_profile,
  delete_services,
  makeservicepublic,
  visitedUsers,
  favouritedUsers,
  send_message_pushes,
  getAdminNotifications,
  homesearch_profiles_services,
  guestUserPlans,
  contactUsPost,
  upgradesubscriptionplan,
  get_recentsearches,
  clear_recent_search,
  create_order,
  verify_signature,
  checking_userexistence,
  userLogout,
  androidDelete,
  androidDeleteOTP,
};
