/** @format */

export const style_exports = {
  grey_color: "#F2F2F2",
  shade_color: "#333333",
  btn_bgcolor: "#04BEF8",
  btn_txtcolor: "#FFFFFF",
  forgt_textcolor: "#FF6E30",
  skyblue_color: "#04BEF8",
  light_skyblue: "#D8F6FF",
  orange_color: "#FF6E30",
  basic_color: "#FFEC9F",
  basicplan_bgcolor: "#FFF5CD",
  standard_btn_clr: "#B1F6F2",
  standard_bg_clr: "#D9FAF8",
  premium_btn_clr: "#B9EEFF",
  premium_btn_clr_monthly: "#90E0EF",
  premium_bg_clr: "#D8F6FF",
  premium_bg_clr_monthly: "#ccf6ff",
  shade_color1: "rgba(51, 51, 51, 0.1)",
  shade_text_color: "#3C3F55",
  black_shade_color: "#1B1B1B",
  favourite_bgcolor: "#F7941D",
  light_green: "#78B030",
  basic_card_color: "#FFF5CD",
  chatBoxBorders: "#80E1FF",
  chatMessageGrayBackground: "#D9D9D9",
  accepted_green_color: "#2b9d29",
};
