import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
export const Usersidebarstyles = makeStyles({
  inactive: {
    textDecoration: "none",
    color: "#333333",
    opacity: "0.6",
    fontSize: "20px",
  },
  active: {
    textDecoration: "none",
    color: style_exports.skyblue_color,
    fontWeight: "700",
    borderBottom: `3px solid ${style_exports.skyblue_color}`,
    fontSize: "20px",
  },
  chipStyles: {
    background: "#FF6E30 !important",
    color: "#fff !important",
    minWidth: "24px",
    height: "24px !important",
    position: "absolute",
    "& span": {
      padding: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      margin: "0.3rem",
    },
  }
});
export const Notifications_styles = makeStyles({
  underline: {
    display: "inline-block",
    padding: "0 10px 10px 0px", // Adjust the distance from the text as needed
    textAlign: "center",
    position: "relative",
    borderRadius: "0.5rem",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "40px", // Adjust the width of the border as needed
      borderBottom: `5px solid ${style_exports.skyblue_color}`,
      borderRadius: "50px"
    },
  },
});
