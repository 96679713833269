/** @format */

import Userapiservice from "@services/Userapiservice";

const adminloginapi = async (payload) => {
  const res = await Userapiservice(
    "adminLogin",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const admin_resetpwd = async (payload) => {
  const res = await Userapiservice(
    "adminResetPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const admin_change_password = async(payload)=>{
  const res = await Userapiservice("adminChangePassword",payload,"POST","application/json");
  return res;
}
const getusers_api = async (payload) => {
  const res = await Userapiservice(
    "getUsersList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_castingcalls_list = async (payload) => {
  const res = await Userapiservice(
    "getCastingCalls",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_competitionslist = async (payload) => {
  const res = await Userapiservice(
    "adminGetAllCompetitions",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_edit_competitions = async (payload) => {
  const res = await Userapiservice(
    "adminCreateOrEditCompetition",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const get_competition_byid = async (payload) => {
  const res = await Userapiservice(
    "viewCompetitionDetailsById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_allcompetantapplicants = async (payload) => {
  const res = await Userapiservice(
    "adminViewAllCastingOrCompetitionApplicants",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const admin_competitionactions = async (payload) => {
  const res = await Userapiservice(
    "updateApplicationStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const view_castingcall_competition_application = async (payload) => {
  const res = await Userapiservice(
    "viewCastingOrCompetitionApplication",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const delete_competitions = async (payload) => {
  const res = await Userapiservice(
    "deleteCompetitionCall",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_admins_list = async (payload) => {
  const res = await Userapiservice(
    "getAdminsList",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const subadmin_creation = async (payload) => {
  const res = await Userapiservice(
    "createEditAdmin",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const adminAccountActions = async (payload) => {
  const res = await Userapiservice(
    "adminAccountActions",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getadminapp_reports = async (payload) => {
  const res = await Userapiservice(
    "adminGetAppReports",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const adminmessage_reply = async (payload) => {
  const res = await Userapiservice(
    "adminReportAppUsage",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getadmin_faqs = async (payload) => {
  const res = await Userapiservice(
    "adminGetAllFaq",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_faq = async (payload) => {
  const res = await Userapiservice(
    "adminCreateOrEditFaq",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_content_management = async (payload) => {
  const res = await Userapiservice(
    "adminGetContentManagement",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const update_content_management = async (payload) => {
  const res = await Userapiservice(
    "adminUpdateContentManagement",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_all_pressnews = async (payload) => {
  const res = await Userapiservice(
    "adminGetAllPressAndNews",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const create_pressnews = async (payload) => {
  const res = await Userapiservice(
    "adminCreateorEditPressAndNews",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const viewordelete_pressnews = async (payload) => {
  const res = await Userapiservice(
    "adminViewOrDeletePressAndNews",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const user_management_actions = async (payload) => {
  const res = await Userapiservice(
    "adminChangeUserAccountStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const subscription_plans = async (payload) => {
  const res = await Userapiservice(
    "adminAddOrEditUserSubscriptionPlan",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getdefault_packages = async (payload) => {
  const res = await Userapiservice(
    "adminGetAllActiveSubscriptionPlans",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getAnalytics = async (payload) => {
  const res = await Userapiservice(
    "getAnalyticsCount",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getDashboardUsersCount = async (payload) => {
  const res = await Userapiservice(
    "getUsersCount",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const getsubadminbyid = async (payload) => {
  const res = await Userapiservice(
    "getadminlistById",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const add_equipmentname = async (payload) => {
  const res = await Userapiservice(
    "addorEditEquipmentName",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const add_brandname = async (payload) => {
  const res = await Userapiservice(
    "addorEditBrandName",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const get_equipmentslist = async (payload) => {
  const res = await Userapiservice(
    "getAdminEquipmentLists",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const addOrEditModel = async (payload) => {
  const res = await Userapiservice(
    "addOrEditEquipment",
    payload,
    "POST",
    "multipart/form-data"
  );
  return res;
};
const admin_forgotpwd = async (payload) => {
  const res = await Userapiservice(
    "adminForgotPassword",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const admin_verify_otp = async (payload) => {
  const res = await Userapiservice("adminVerifyOtp", payload, "POST", "application/json");
  return res;
};
const adminStatus = async (payload) => {
  const res = await Userapiservice(
    "adminHoldStatus",
    payload,
    "POST",
    "application/json"
  );
  return res;
};
const admin_user_login = async(payload) => {
  const res = await Userapiservice("userAccountLoginFromAdmin",payload,"POST","application/json");
  return res;
}
const subadminprivileges = async(payload) => {
  const res  = await Userapiservice("getAdminPrivileges",payload,"POST","application/json");
  return res
}
const adminanalytics = async(payload) => {
  const res = await Userapiservice("analytics",payload,"POST","application/json");
  return res;
}
const adminlogout = async(payload) => {
  const res  = await Userapiservice("adminLogout",payload,"POST","application/json");
  return res;
}
const addEditHomeBanner = async(payload) => {
  const res = await Userapiservice("addOrEditHomeBanner",payload,"POST","multipart/form-data");
  return res;
}
const addTestimonial = async(payload) => {
  const res = await Userapiservice("addOrEditClientTestimonial",payload,"POST","multipart/form-data");
  return res;
} 
const addHowItworksContent = async(payload) => {
  const res = await Userapiservice("addOrEditHowitWorks",payload,"POST","application/json");
  return res;
}
const adminMultiDeleter = async(payload) => {
  const res = await Userapiservice("adminDeleteContentManagement",payload,"POST","application/json");
  return res;
}
const addOrEditAboutUs = async(payload) => {
  const res = await Userapiservice("addorEditAboutUs",payload,"POST","multipart/form-data");
  return res;
}

export {
  adminloginapi,
  admin_resetpwd,
  getusers_api,
  get_castingcalls_list,
  get_competitionslist,
  create_edit_competitions,
  get_competition_byid,
  get_allcompetantapplicants,
  admin_competitionactions,
  get_admins_list,
  subadmin_creation,
  adminAccountActions,
  getadminapp_reports,
  adminmessage_reply,
  getadmin_faqs,
  create_faq,
  get_content_management,
  update_content_management,
  get_all_pressnews,
  create_pressnews,
  viewordelete_pressnews,
  user_management_actions,
  subscription_plans,
  getdefault_packages,
  delete_competitions,
  getAnalytics,
  getDashboardUsersCount,
  view_castingcall_competition_application,
  getsubadminbyid,
  add_equipmentname,
  add_brandname,
  get_equipmentslist,
  addOrEditModel,
  admin_forgotpwd,
  admin_verify_otp,
  adminStatus,
  admin_user_login,
  admin_change_password,
  subadminprivileges,
  adminanalytics,
  adminlogout,
  addEditHomeBanner,
  addTestimonial,
  addHowItworksContent,
  adminMultiDeleter,
  addOrEditAboutUs,
};
