import {
  addDoc,
  collection,
  doc,
  writeBatch,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import {
  add_remove_deletedusers,
  getting_sender_receiverids,
  update_unseenmesssages_count,
} from "@helpers/Firebasehelpers";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import { db } from "@firebases/Firebaseconfig";
import { orderBy, limit, startAfter } from "firebase/firestore";
import { concatinate_displayname } from "@helpers/Helperfunctions";

/**--------------------------------------------Firebase collection Declaration and Initialization-------------------------------------------- */
const chatroom_collection = collection(db, "chat_room");
const conversation_collection = collection(db, "conversation");
const users_collection = collection(db, "users_info");
const adminroom_collection = collection(db, "admin_chats");
const chat_connection = collection(db, "chat_connections");
const deactivated_users_collection = collection(db, "DeletedUsers");
const batch = writeBatch(db);
const MAX_BATCH_SIZE =450;

/**----------------------------------------Functions-------------------------------------------------- */

/**
 * Creating the user with the unique id generated by create profile
 * @param {document} users_collection
 * @param {object} data
 */
const create_user = async (data, subcategory) => {
  /**Firebase users info data */
  let user_doc = {
    userId: data?.id,
    name: data?.firstName ?? "" + " " + data?.lastName ?? "",
    photo: data?.profileImage,
    deviceIDs: [],
    planStatus: 1,
    isAdmin: false,
    Role: subcategory?.subcategoryName,
    badge: {},
    chatConnections: 0,
  };
  /**users collection setting userid as document id */
  let user_ref = doc(users_collection, data?.id);
  /**saving document data on the created id */
  try {
    await setDoc(user_ref, user_doc);
  } catch (err) {
    console.log(err);
  }
};
const update_user = async (data,subcategory) => {
  try {
    const user_ref = doc(users_collection, data?.id);
    const docSnapshot = await getDoc(user_ref);
    if (docSnapshot.exists()) {
    let update_data = {
      name: concatinate_displayname(data?.firstName, data?.lastName),
      photo: data?.profileImage,
    };
    await updateDoc(user_ref, update_data);
    return { status: 1 };
  }
  else{
   await create_user(data,subcategory)
  }
  } catch (err) {
    console.log(err);
  }
};

// const create_admin_chatroom =
/**Retrieving particular users info from users_info collection */
const retrieve_user = async (data) => {
  try {
    const updateref = await doc(users_collection, data);
    const users_document = await getDoc(updateref);
    return { status: 1, data: users_document?.data() };
  } catch (err) {
    return { status: 0, message: err };
  }
};

/**
 * Retrieving the users data present in the data
 * @param {array} data
 * @returns
 */
const retrieve_users_list = async (data) => {
  try {
    const user_list = await getDocs(
      query(users_collection, where("userId", "in", data))
    );
    let users = [];
    user_list.docs.map((item) => {
      users.push(item.data());
    });
    return { status: 1, data: users };
  } catch (err) {
    return { status: 0, message: err };
    console.log(err);
  }
};

/**Checking for chatroom existence when we are contacting that user */
const checking_chatroom_existence = async (data) => {
  try {
    const { receiverdata, senderdata, chattype } = data;
    const {
      userId: senderid,
      userDetails: senderdetails,
      subcategory: sendercategory,
    } = senderdata;
    const { userId: receiverid, userDetails, category } = receiverdata;
    const sender_obj = {
      userId: senderid,
      name:
        senderdetails?.firstName ?? "" + " " + senderdetails?.lastName ?? "",
      role: sendercategory?.subcategoryName ?? "",
    };
    const receiver_obj = {
      userId: receiverid,
      name: userDetails?.firstName ?? "" + "" + userDetails?.lastName ?? "",
      role: category?.categoryName ?? "",
    };
    // Check if a chat room already exists with the given sender and receiver IDs

    let existingChatRoomQuery;
    if ([senderid, receiverid].includes("Admin")) {
      let checkingid = senderid === "Admin" ? receiverid : senderid;
      existingChatRoomQuery = query(
        adminroom_collection,
        where("memberIds", "array-contains", checkingid)
      );
    } else {
      existingChatRoomQuery = query(
        chatroom_collection,
        where("chatType", "==", 1),
        where("userId", "==", senderid),
        where("memberIds", "array-contains", senderid)
      );
    }

    const existingChatRooms = await getDocs(existingChatRoomQuery);
    /**chat room exists */
    if (existingChatRooms.docs.length) {
      const found_roomobj = existingChatRooms.docs.find(
        (item) =>
          JSON.stringify(item.data().memberIds.sort()) ===
          JSON.stringify([senderid, receiverid].sort())
      );
      if (found_roomobj === undefined) {
        let chatroom = {
          id: "",
          roomID: new Date().getTime().toString(),
          chatType: chattype,
          memberIds: [senderid, receiverid],
          memberList: [sender_obj, receiver_obj],
          muteStatus: false,
          recentMessage: {},
          recentMessageTime: serverTimestamp(),
          roomName: "",
          isArchevied: false,
          count: { [senderid]: 0, [receiverid]: 0 },
          archeviedUsers: [],
          deletedUsers: [],
          blockedUsers: [],
          nonDeletedUsers: [senderid],
          userId: senderid,
          roomusers:`${senderid}_${receiverid}`
        };

        return { exists: false, room: chatroom };
      } else {
        let found_obj = { ...found_roomobj.data(), id: found_roomobj.id };
        if ([senderid, receiverid].includes("Admin")) {
          found_obj.chatType = 1;
          found_obj.isAdmin = true;
        }
        if (found_obj?.deletedUsers?.includes(senderid)) {
          found_obj.id = "";
        }
        return {
          exists: true,
          room: found_obj,
        };
      }
    } else {
      /**chatroom didn't exitst creating the chatroomobj*/
      let chatroom;
      if ([senderid, receiverid].includes("Admin")) {
        let otherid = senderid === "Admin" ? receiverid : senderid;
        chatroom = {
          recentMessage: {},
          recentMessageTime: serverTimestamp(),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          roomName: "Yourmoca",
          roomID: new Date().getTime().toString(),
          userId: senderid,
          memberIds: [otherid, "Admin"],
          archeviedUsers: [],
          id: "",
          chatType: 1,
          isAdmin: true,
          blockedUsers: [],
          nonDeletedUsers: [otherid, "Admin"],
          deletedUsers: [],
        };
      } else {
        chatroom = {
          id: "",
          roomID: new Date().getTime().toString(),
          chatType: chattype,
          memberIds: [senderid, receiverid],
          memberList: [sender_obj, receiver_obj],
          muteStatus: false,
          recentMessage: {},
          recentMessageTime: serverTimestamp(),
          roomName: "",
          isArchevied: false,
          count: { [senderid]: 0, [receiverid]: 0 },
          archeviedUsers: [],
          deletedUsers: [],
          blockedUsers: [],
          nonDeletedUsers: [senderid],
          userId: senderid,
        };
      }

      return { exists: false, room: chatroom };
    }
  } catch (err) {
    console.log(err);
  }
};

/**Checking for group chat existence */
const checking_groupchatroom_existence = async (data) => {
  try {
    const {
      project_details: { projectId, projectName: projectname },
      chattype,
    } = data;
    let selected_ids = [
      ...data?.selectedusers.map((item) => item?.serviceUserDetails?.id),
      data?.senderdata?.userId,
    ];
    let unique_users = [...new Set(selected_ids)]
    // Check if a group chat room already exists with the given sender and receiver IDs
    const existingChatRoomQuery = query(
      chatroom_collection,
      where("roomID", "==", projectId.toString())
    );
    const group_chatroom = await getDocs(existingChatRoomQuery);
    if (group_chatroom.docs.length) {
      const room_doc = group_chatroom.docs[0].data();
      const user_exists_check = unique_users.some((item)=>room_doc.deletedUsers.includes(item));
      if(user_exists_check){
        return{
          exists:false,
          room:{
            ...room_doc,
            id:room_doc?.id,
            memberIds:unique_users,
            nonDeletedUsers:unique_users,
            deletedUsers:room_doc.deletedUsers.filter(item=>!unique_users.includes(item))
          }
        }
      }
      else{
        return {
          exists: true,
          room: {
            ...room_doc,
            id: room_doc?.id,
          },
        };
      }
     
    } else {
    
      let room = {
        roomID: projectId.toString(),
        chatType: chattype,
        memberIds: unique_users,
        memberList: [],
        muteStatus: {},
        recentMessage: {},
        recentMessageTime: serverTimestamp(),
        roomName: projectname,
        archeviedUsers: [],
        count: {},
        deletedUsers: [],
        blockedUsers: [],
        nonDeletedUsers: unique_users,
        userId: "",
        quotesList: [],
        id: "",
      };
      return { exists: false, room };
    }
  } catch (err) {
    console.log(err);
  }
};
/**
 * Creating and updating one-one chat room if their chat roomid is not exists
 * @param {*} collection
 * @param {*} data
 */
const create_update_chatroom = async (data) => {
  try {
    const { selected, id } = data;
    /**User already exists */
    let sender_chatroom = {
      ...selected,
      adminId: selected?.userId,
    };
    let receiverid = selected?.memberIds.filter(
      (userid) => userid !== selected?.userId
    )[0];
    let receiver_chatroom = {
      ...selected,
      userId: receiverid,
      adminId: receiverid,
      nonDeletedUsers: [receiverid],
    };
    /**updating exising chatrooms */
    if (selected?.deletedUsers.length > 0) {
      const chatroom_docs = await getDocs(
        query(chatroom_collection, where("roomID", "==", selected?.roomID))
      );
      let room_docs = chatroom_docs.docs;
      for (let i = 0; i <= room_docs.length - 1; i++) {
        let room_doc = room_docs[i];
        let update_data = {
          ...room_doc.data(),
          deletedUsers: [],
          nonDeletedUsers:[room_doc.data().userId]
        };
        if (room_doc.data()?.userId === id) {
          update_data.deletedUsers = [];
          update_data.nonDeletedUsers = [id];
          sender_chatroom = {
            ...update_data,
            id: room_doc.id,
          };
        }
        await updateDoc(room_doc.ref, update_data);
      }
      return { status: 1, chat_room: { ...sender_chatroom } };
    } else {
      /**Creating new chatroom */
      const receiver_docRef = await addDoc(
        chatroom_collection,
        receiver_chatroom
      );
      const sender_docRef = await addDoc(chatroom_collection, sender_chatroom);
      const newChatroomId = sender_docRef.id; // Get the ID of the newly created document
      return {
        status: 1,
        chat_room: { ...sender_chatroom, id: newChatroomId },
      };
    }
  } catch (err) {
    console.log(err);
    return { status: 0, message: "Error in chatroom" };
  }
};

const update_receivers_room = async (data) => {
  try {
    const { receiverid, roomid } = data;
    const room_query = query(
      chatroom_collection,
      where("roomID", "==", roomid),
      where("userId", "==", receiverid)
    );
    const room_docs = await getDocs(room_query);
    let room_doc = room_docs.docs[0];
    const update_data = {
      ...room_doc.data(),
      deletedUsers: [],
      nonDeletedUsers: [receiverid],
    };
    updateDoc(doc(chatroom_collection, room_doc.id), update_data);
    return { status: 1, chat_room: update_data };
  } catch (err) {
    console.log(err);
    return { status: 0, chat_room: {} };
  }
};
/**Admin chat room creation */
const create_admin_chatroom = async (data) => {
  try {
    const admin_room = {
      recentMessage: {},
      recentMessageTime: serverTimestamp(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      roomName: "Yourmoca",
      roomID: new Date().getTime().toString(),
      userId: data?.isAdmin ? "Admin" : data?.id,
      memberIds: [data?.id, "Admin"],
      archeviedUsers: [],
      nonDeletedUsers: [data?.id, "Admin"],
    };
    const admin_doc_ref = doc(adminroom_collection, data?.id);
    // Check if the document exists
    let getusersquery = query(
      adminroom_collection,
      where("memberIds", "array-contains", data?.id)
    );
    let result = await getDocs(getusersquery);
    let admin_doc;
    if (result.docs.length > 0) {
      // const docSnapshot = await getDoc(admin_doc_ref);
      admin_doc = await updateDoc(admin_doc_ref, admin_room);
    } else {
      admin_doc = await setDoc(admin_doc_ref, admin_room);
    }

    const newChatroomId = data?.id; // Get the ID of the newly created document
    return {
      status: 1,
      chat_room: {
        ...admin_room,
        id: newChatroomId,
        chatType: 1,
        isAdmin: true,
        blockedUsers: [],
      },
    };
  } catch (err) {
    console.log(err);
    return { status: 0, message: "Error in chatroom" };
  }
};

/**
 * 
 * @param {object} chatroom - rooms result 
 * @param {array} selectedusers 
 * @returns 
 */
const checking_selecteduserservices = async (chatroom, selectedusers) => {
  try {
    let chatroom_serviceslist = {};
    if (Object.keys(chatroom).length) {
      /**Room Exists */
      chatroom_serviceslist = chatroom?.userServices ?? {};
    } 
    for (let i = 0; i <= selectedusers.length - 1; i++) {
      let estimation_userid = selectedusers[i].serviceUserId;
      let serviceid =
        selectedusers[i].serviceType === 1 ? 0 : selectedusers[i]?.serviceId;
      if (chatroom_serviceslist.hasOwnProperty(estimation_userid)) {
        let serviceidindex =
          chatroom_serviceslist[estimation_userid].serviceIds.indexOf(
            serviceid
          );
        serviceidindex > -1
          ? chatroom_serviceslist[estimation_userid].serviceIds.splice(
              serviceidindex,
              1
            )
          : chatroom_serviceslist[estimation_userid].serviceIds.push(
              serviceid
            );

        if (chatroom_serviceslist[estimation_userid].serviceIds.length === 0) {
          delete chatroom_serviceslist[estimation_userid];
        }
      } else {
        chatroom_serviceslist[estimation_userid] = {
          serviceIds: [serviceid],
        };
      }
    }
    console.log(chatroom_serviceslist,"--returning services list")
    return chatroom_serviceslist;
  } catch (err) {
    console.log(err);
  }
};

/**Creating and updating the groupchat case */
const create_update_groupchatroom = async (data, selectedchat) => {
  try {
    const {
      project_details: { projectId, projectName: projectname },
      admin_details,
      chattype,
      selectedusers,
    } = data;
    let member_ids = [
      ...new Set(
        selectedusers.map((item) => item?.serviceUserDetails?.id),
        admin_details?.id
      ),
    ];
    // Check if a chat room already exists with the given roomid
    const existingChatRoomRef = query(
      chatroom_collection,
      where("roomID", "==", selectedchat?.roomID.toString())
    );
    const group_chatroom = await getDocs(existingChatRoomRef);
    let roomdoc = !group_chatroom?.empty ? group_chatroom.docs[0].data() :{}
    const userservices_list = await checking_selecteduserservices(
      roomdoc,
      [...selectedusers,{
        serviceUserId: admin_details?.id,serviceType:1}]
    );
    member_ids = Object.keys(userservices_list);
    if (!group_chatroom?.empty) {
      /**Chat room exists but also need to check all the selected members present in the chatroom 
      else we need to update the memebers list,nondeleted users and deleted users**/
      let room_data = group_chatroom.docs[0].data();
      const { deletedUsers, memberIds } = room_data;
      let doc_memberids = memberIds;
      let deleted_users = [...deletedUsers];
      for (const memberid of member_ids) {
        /**if memberid present in deletedUsers removing it from deleted users and adding it to doc_memberids */
        deleted_users = deleted_users.filter((id) => id !== memberid);
        if (!doc_memberids.includes(memberid)) {
          doc_memberids.push(memberid);
        }
      }
      // deleted_users = deletedUsers.filter((id) => id !== memberid)
      // Update the deletedUsers field in the existing document
      let chat_room_data = await updateDoc(
        doc(chatroom_collection, group_chatroom.docs[0]?.id),
        {
          deletedUsers: deleted_users,
          memberIds: doc_memberids,
          nonDeletedUsers: doc_memberids,
          userServices: userservices_list,
        }
      );
      return {
        status: 1,
        chat_room: {
          ...group_chatroom.docs[0].data(),
          id: group_chatroom.docs[0]?.id,
        },
      };
    } else {
      //Need to create the new chatroom in group chat case
      let room = {
        roomID: projectId.toString(),
        chatType: chattype,
        memberIds: member_ids,
        memberList: [],
        muteStatus: {},
        recentMessage: {},
        recentMessageTime: serverTimestamp(),
        roomName: projectname,
        archeviedUsers: [],
        count: {},
        deletedUsers: [],
        blockedUsers: [],
        nonDeletedUsers: member_ids,
        userId: admin_details?.id,
        quotesList: [],
        id: "",
        adminId: admin_details?.id,
        userServices: userservices_list,
      };
      const room_creation = await addDoc(chatroom_collection, room);
      return { status: 1, chat_room: { ...room, id: room_creation?.id } };
    }
  } catch (err) {
    console.log(err);
    return { status: 0, message: "Error in group chatroom creation" };
  }
};
/**update message in chatroom */
const update_chatroom_message = async (data) => {
  try {
    const {
      selected,
      message,
      profile_data: { userId: id },
    } = data;
    const { memberIds, roomID } = selected;
    const { blockedUsers } = message;
    const collection_ref = selected?.isAdmin
      ? adminroom_collection
      : chatroom_collection;
    const updateref = await query(
      collection_ref,
      where("roomID", "==", roomID)
    );
    const roomsnapshot = await getDocs(updateref);

    if (!roomsnapshot.empty) {
      roomsnapshot.forEach(async (document) => {
        if (!blockedUsers.includes(document.data().userId)) {
          let doc_val = document.data();
          let quote_list = doc_val?.quotesList ?? [];
          let updated_quote_list = [];
          if (message?.contentType === 6) {
            let old_quotes = [...quote_list];
            const filtered_quotes = old_quotes.filter(
              (quote) =>
                quote?.estimationId !== message?.quoteInfo?.estimationId
            );
            updated_quote_list = [
              ...filtered_quotes,
              {
                ...message.quoteInfo,
                messageDocId: message?.id,
              },
            ];
          } else if (message?.contentType === 7) {
            let old_quotes = [...quote_list];
            const filtered_quotes = old_quotes.filter(
              (quote) =>
                quote?.estimationId !== message?.quoteInfo?.estimationId
            );
            updated_quote_list = [...filtered_quotes];
          } else {
            updated_quote_list = [...quote_list];
          }
          // Update the specific document in the batched operation
          const docRef = doc(collection_ref, document.id);
          updateDoc(docRef, {
            recentMessage: message,
            recentMessageTime: serverTimestamp(),
            quotesList: updated_quote_list,
          });
        }
      });

      return { status: 1 };
    } else {
      customEnqueueSnackbar("Docuemnt doesn't exists", { variant: "error" });
    }
  } catch (err) {
    console.log(err);
  }
};

const update_similar_quotemessages = async (estimationids) => {
  try {
    const msgs_query = query(
      conversation_collection,
      where("quoteInfo.estimationId", "in", estimationids),
      where("quoteInfo.isDisabled", "==", false)
    );
    const querySnapshot = await getDocs(msgs_query);
    if (!querySnapshot.empty) {
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, {
          quoteInfo: { ...doc.data().quoteInfo, isDisabled: true },
        });
      });
      await batch.commit();
      return {status:1,message:"Quotes message updated succesfully"}
    } else {
      return { status: 2, message: "no docs found" };
    }
  } catch (err) {
    return {status:0,message:err}
  }
};

const delete_estimationmembers = async(estimationmembers,estimationids,userid)=>{
  try{
    const similar_quotes_res = await update_similar_quotemessages(estimationids);
    switch(similar_quotes_res?.status){
      case 1:
        console.log("Messages exists updated succesfully");
        let quoterooms = estimationmembers.flatMap((item)=>[`${item?.serviceUserId}_${userid}`,`${userid}_${item?.serviceUserId}`]);
        const rooms_query = query(chatroom_collection,where("roomusers","in",quoterooms))
        const roomsres = await getDocs(rooms_query);
        if(!roomsres.empty){
          const rooms_docs = roomsres.docs;
          for (let i=0; i<=rooms_docs.length-1; i++){
            const roomid = rooms_docs[i].id;
            const room_doc = rooms_docs[i].data();
            const quotes_list = room_doc.quotesList
            let updated_room = {
              quotesList:quotes_list.length>0 ?quotes_list.filter((item)=>!estimationids.includes(item?.estimationId)):[]
            }
            await updateDoc(doc(chatroom_collection,roomid),updated_room);
          }
            // Need to check for estimation ids
        } 
        else{

        }
      // Need to write the deletion case for removal of quotes from quotes info by placing their estimationids in a array
        break;
      case 2:
        console.log("no docs found");
        break;
      default:
        console.log(similar_quotes_res?.message);
    }
  }
  catch(err){
    console.log(err);
  }
}
/**
 *Updating the groupchat room if the send quote is end it is removing from quotelist also changing
 sentstatus of quoteinfo  to true in the conversation 
 * @param {object} data
 */
const update_groupchatroom_quoteslist = async (data) => {
  try {
    const { selected, message } = data;
    const { roomID } = selected;
    const updateref = await query(
      chatroom_collection,
      where("roomID", "==", roomID)
    );
    const roomsnapshot = await getDocs(updateref);
    if (!roomsnapshot.empty) {
      let document = roomsnapshot.docs[0];
      let update_room = {
        ...document.data(),
        quotesList: document
          .data()
          .quotesList // .pop()
          .filter((q) => q.messageDocId !== message?.id),
      };
      const message_docref = doc(conversation_collection, message?.id);
      // Get the current document data
      const docSnapshot = await getDoc(message_docref);
      const currentData = docSnapshot.data();
      // Merge the existing quoteInfo object with the updated sentStatus
      const updatedQuoteInfo = {
        ...currentData.quoteInfo,
        sentStatus: true,
      };
      await updateDoc(message_docref, {
        quoteInfo: updatedQuoteInfo,
      });
      const docRef = doc(chatroom_collection, document.id);
      await updateDoc(docRef, update_room);
    }
  } catch (err) {
    console.log(err);
  }
};
/**
 * update badgecounts on sending and receiving the messages in users_info collection
 * @param {uniqueroomid} roomid
 * @param {uniquesenderid} sender_id
 * @param {integer} type
 *
 * if type === 1 -> while sending we have make other users document previous roomid val+1
 * increment the badge for  at particular roomid previous value+1
 * else type === 0 ->
 * This is used while reading the messages and making that particular roomid badge value to 0 in our users_info document
 * Make the badge particular roomid to 0
 */
const update_badge_count = async (type, sender_id, roomid, admin, selected) => {
  try {
    if (roomid === undefined) {
      return;
    }
    let collection_ref = admin ? adminroom_collection : chatroom_collection;
    const room_ref = query(
      collection_ref,
      where("roomID", "==", roomid.toString())
    );
    const room_snapshot = await getDocs(room_ref);
    if (type === 1) {
      const { memberIds } = room_snapshot.docs[0].data();
      const memberids = memberIds.filter((id) => id !== sender_id);
      for (let mem_id = 0; mem_id <= memberids.length - 1; mem_id++) {
        const user_ref = doc(users_collection, memberids[mem_id]);
        const user_doc = await getDoc(user_ref);
        let user_docval = user_doc.data();
        /**Room id exists  */
        if (user_docval?.badge !== undefined && roomid in user_docval?.badge) {
          user_docval.badge[roomid] += 1;
        } else {
          user_docval.badge[roomid] = 1;
        }
        await updateDoc(user_ref, user_docval);
      }
    }
    const user_ref = doc(users_collection, sender_id);
    const user_doc = await getDoc(user_ref);
    let user_docval = user_doc.data();
    user_docval.badge[roomid] = 0;
    await updateDoc(user_ref, user_docval);
    room_snapshot.docs.forEach(async (room_doc) => {
      const room_data = room_doc.data();
      const roomId = room_doc.id; // Retrieve the room ID
      // Update the document with the room ID
      await updateDoc(doc(collection_ref, roomId), {
        roomID: roomid,
        updatedAt: serverTimestamp(),
      });
    });
  } catch (err) {
    console.log(err);
  }
};

const get_updated_chatroom = async (roomid) => {
  try {
    const room_doc = doc(chatroom_collection, roomid);
    const room_snapshot = await getDoc(room_doc);
    return {
      status: 1,
      data: { ...room_snapshot?.data(), id: room_snapshot.id },
    };
  } catch (err) {
    console.log(err);
    return { status: 0, message: err };
  }
};
/**Creat or update message */
const create_update_message = async (data, isAdmin) => {
  try {
    let memids;
    let msg_payload;
    const {
      profile_data,
      message,
      content_type,
      messageid,
      selected: {
        roomID: roomid,
        chatType,
        blockedUsers: roomblockedusers = [],
        memberIds = [],
        nonDeletedUsers,
        deletedUsers,
      },
      fileName,
      fileUrl,
      fileSize,
      fileType,
      thumbnail_url,
      storefilename,
      quoteinfo,
      applicationStatus_Mesg,
    } = data;
    const non_blocked_users = memberIds.filter(
      (id) => !roomblockedusers.includes(id)
    );
    const { userDetails: { firstName = "", lastName = "" } = {} } =
      profile_data;
    let sender_id = "";
    let receiver_id = "";
    /**chat type=1 -> one-one chat */
    if (chatType === 1) {
      const ids = await getting_sender_receiverids(
        profile_data,
        data?.selected
      );
      const { senderid, receiverid } = ids;
      memids = [senderid, receiverid];
      sender_id =
        (window?.location?.pathname === "/admin/messages" || isAdmin === "isAdmin") ? "Admin" : senderid;
      receiver_id = receiverid;
    }

    /**This data is used for seen users */
    let senderdata = {
      id: sender_id,
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      photo: "",
    };
    /**One-one chat message payload */
    msg_payload = {
      messageID: new Date().getTime().toString(),
      contentType: content_type,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      text: [1, 5].includes(content_type) ? message : "",
      fileUrl: fileUrl,
      fileName: fileName,
      fileSize: fileSize,
      thumbUrl: thumbnail_url,
      storeFileName: storefilename,
      duration: "",
      senderID:
        (window?.location?.pathname === "/admin/messages" || isAdmin === "isAdmin") ? "Admin" : sender_id,
      roomID: roomid,
      isReply: false,
      replyFIleName: "",
      replyMessageId: "",
      replyMessageUser: "",
      replyMessage: "",
      replyMessageContentType: "",
      replyMessageFileUrl: "",
      isForward: false,
      forwardMessageID: "",
      forwardMessageUser: "",
      seenUser: [senderdata],
      seenUserids: [sender_id],
      deletedUsers: [],
      nonDeletedUsers: non_blocked_users,
      quoteInfo: quoteinfo,
      readStatus: [],
      deliverdUser: [],
      fileType: fileType,
      blockedUsers: roomblockedusers,
      nonBlockedUsers: non_blocked_users,
      estimationId: [6, 7].includes(content_type) ? quoteinfo?.estimationId : 0,
      applicationStatusMesg: applicationStatus_Mesg, //Casting call,competition application status
    };
    if (chatType === 2) {
      msg_payload.seenUser = [];
      msg_payload.seenUserids = [profile_data?.userId];
      msg_payload.senderID = profile_data?.userId;
      msg_payload.nonDeletedUsers = nonDeletedUsers;
      msg_payload.deletedUsers = deletedUsers;
      sender_id = profile_data?.userId;
    } else {
      if (![sender_id, receiver_id].includes("Admin")) {
        await create_chatroom_connection({
          id: `${receiver_id}-${sender_id}`,
          receiver_id,
          sender_id,
        });
      }
    }
    /**message id exist then it is used for edit message */
    if (messageid) {
      /**edit the existing message logic goes here */
    } else {
      if (content_type === 6) {
        await update_similar_quotemessages([quoteinfo?.estimationId]);
      }
      /**Createing new message document */
      const docRef = await addDoc(conversation_collection, msg_payload);
      const docId = docRef.id;
      msg_payload.id = docId;
      /**updating the badge count in users collection */
      if (!roomblockedusers.includes(receiver_id)) {
        await update_badge_count(1, sender_id, roomid, data?.selected?.isAdmin);
      }
      return { status: 1, message: msg_payload };
    }
  } catch (err) {
    console.log(err, "error");
    return { status: 0, message: "Error in sending message" };
  }
};

/**
 *
 * @param {object} data
 * @returns
 */
const create_chatroom_connection = async (data) => {
  try {
    const { id, sender_id, receiver_id } = data;
    const get_connections = await getDocs(
      query(
        chat_connection,
        where("senderId", "==", sender_id),
        where("reciverId", "==", receiver_id)
      )
    );
    if (get_connections?.docs?.length) {
      return { status: 0, message: "Connection already exists" };
    } else {
      const connection_doc = {
        id,
        senderId: sender_id,
        reciverId: receiver_id,
        createdAt: serverTimestamp(),
      };
      const docRef = await addDoc(chat_connection, connection_doc);
      const user_doc = await doc(users_collection, receiver_id);
      const user_snapshot = await getDoc(user_doc);
      const user_data = user_snapshot.data();
      let connections = user_data?.chatConnections
        ? user_data?.chatConnections + 1
        : 1;
      const update_userdoc = await updateDoc(user_doc, {
        chatConnections: connections,
      });
      return { status: 1, data: { ...connection_doc, docid: docRef.id } };
    }
  } catch (err) {
    console.log(err);
  }
};
/**We have performing actions on conversation collectioni */
const getuser_unseen_messages = async (data) => {
  try {
    const {
      senderdata: {
        userId: senderid,
        userDetails: { name },
      },
      roomdata: { id: roomid },
    } = data;
    const getmsgs_query = query(
      conversation_collection,
      where("roomID", "==", roomid),
      where("recieverID", "==", senderid)
    );
    const querySnapshot = await getDocs(getmsgs_query);
    let messages = [];
    let sender_data = {
      id: senderid,
      name,
      photo: "",
    };
    querySnapshot.forEach((docs) => {
      let message = docs.data();
      /**checking my userid is present in seenuserids if not then i am adding my userid to that seen userid and updating the message document */
      if (!message?.seenUserids.includes(senderid)) {
        let updated_msg = {
          ...message,
          seenUserids: [...message.seenUserids, senderid],
          seenUser: [...message?.seenUser, sender_data],
        };
        let msgref = doc(collection, docs.id);
        updateDoc(msgref, updated_msg);
        messages.push({ ...updated_msg, id: doc.id });
      }
    });
    return true;
  } catch (err) {}
};

/**update seen users in the message */
const update_message_seenusers = async (data) => {
  try {
    const { profile_data, selecteduser } = data;
    // let messagedata = {
    //   roomdata: selecteduser,
    //   senderdata: profile_data,
    // };
    // let unseen_messages = await getuser_unseen_messages(
    //   messagedata
    // );
    // if (unseen_messages) {
    //   let data = {
    //     selected: selecteduser,
    //     message: "",
    //     profile_data,
    //     usertype: 2,
    //   };
    //   const updateref = await doc(chatroom_collection, selecteduser?.id);
    //   const messagesnapshot = await getDoc(updateref);
    //   let usertype = 2;
    //   const prevcount = await update_unseenmesssages_count(
    //     messagesnapshot.data(),
    //     data?.profile_data,
    //     usertype
    //   );
    //   await updateDoc(updateref, {
    //     // updatedAt: serverTimestamp(),
    //     count: prevcount,
    //   });
    // }
  } catch (err) {}
};

// Function to get the total count of messages
const getTotalMessagesCount = async (selected) => {
  try {
    const msgs_query = await query(
      conversation_collection,
      where("roomID", "==", selected?.roomID)
    );
    const snapshot = await getDocs(msgs_query);
    return { status: 1, data: snapshot.docs };
  } catch (error) {
    console.error("Error getting total messages count: ", error);
  }
};

/**Updating the archieve and unarchieve users list in chat room collection
 *
 * @param {array} selecteusers
 * @param {integer} tabvalue
 * @returns
 */
const update_archievelist = async (selecteusers, userid) => {
  try {
    const batch = writeBatch(db);
    await Promise.all(
      selecteusers.map(async (obj) => {
        let collection_ref = obj.isAdmin
          ? adminroom_collection
          : chatroom_collection;
        const docRef = await doc(collection_ref, obj.id);
        const each_doc = await getDoc(docRef);
        if (each_doc.exists) {
          const data_val = each_doc.data();
          const archeviedUsers = data_val.archeviedUsers || [];
          if (!archeviedUsers.includes(userid)) {
            // If user ID not present in archeviedUsers, add it
            archeviedUsers.push(userid);
          } else if (archeviedUsers.includes(userid)) {
            // If user ID present in archeviedUsers and archieved is false, remove it
            const index = archeviedUsers.indexOf(userid);
            archeviedUsers.splice(index, 1);
          }
          batch.update(docRef, { archeviedUsers });
        }
      })
    );
    await batch.commit();
    return { status: 1, message: "Archived the users successfully" };
  } catch (err) {
    console.log(err);
    return { status: 0, message: err };
  }
};

/**Retriving images,videos,links,documents from the conversation table */
const retrieving_media_data = async (
  selecteduser,
  tabvalue,
  userid,
  startafterdoc
) => {
  try {
    let content_vals = tabvalue === 1 ? [2, 3] : tabvalue === 2 ? [4] : [5];
    let getmsgs_query = query(
      conversation_collection,
      where("roomID", "==", selecteduser?.roomID),
      where("contentType", "in", content_vals),
      where("nonDeletedUsers", "array-contains", userid),
      orderBy("createdAt", "asc"),
      limit(20)
    );
    if (startafterdoc) {
      getmsgs_query = query(
        conversation_collection,
        where("roomID", "==", selecteduser?.roomID),
        where("contentType", "in", content_vals),
        where("nonDeletedUsers", "array-contains", userid),
        orderBy("createdAt", "asc"),
        startAfter(startafterdoc),
        limit(20)
      );
    }
    const querySnapshot = await getDocs(getmsgs_query);

    // Extract documents from query snapshot
    let messages = [];
    if (querySnapshot.docs.length) {
      messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    }
    return { status: 1, data: messages };
  } catch (err) {
    console.log(err);
    return { status: 0, message: err };
  }
};

/**Clear chat by placing our userids in deletedusers param and removing from nodeletedusers from in
 *  both chatroom recent_message and conversation of each message in that particular chat room
 * userids are array because we have deleting the multiple users from the estimation list at a time
 * so we also need to place all userids in messages deleted users array
 * @param {object} selected
 * @param {array} userid
 * @returns
 */
const clear_chat = async (selected, userids) => {
  try {
    if (!selected?.id) {
      return;
    }
    let updated_recent_message;
      // Function to commit a batch and reset it
      const commitBatch = async (batch) => {
        if (!batch.isEmpty) {
          await batch.commit();
        }
      };
    // Create another batch for updating the chatroom document
    const updateBatch = writeBatch(db);
    let batchCounter = 0;
    let all_msgs = await getTotalMessagesCount(selected);
    if (all_msgs?.status) {
      if (Array.isArray(all_msgs?.data)) {
        // await Promise.all(
          // (all_msgs?.data).map(async (msg) => 
            for (const msg of all_msgs.data){
            const data_val = msg.data();
            const messageID = msg.id;
            const del_users_res = await add_remove_deletedusers(
              data_val,
              userids
            );
            let { deletedUsers, nonDeletedUsers } = del_users_res;

            // Construct the document reference for the current message
            const docRef = doc(conversation_collection, messageID);
            
            // Update the document in the batch
            updateBatch.update(docRef, { deletedUsers, nonDeletedUsers });
            batchCounter++;
            if (batchCounter === MAX_BATCH_SIZE) {
              await commitBatch(updateBatch);
              updateBatch = writeBatch(db);
              batchCounter = 0;
            }
          }
        // )
        // );
        // await updateBatch.commit();
        await commitBatch(updateBatch);
      } else {
        return;
      }
      /**retriving chatroom
       * Deleting the recent message also
       */
      let collection_ref = selected?.isAdmin
        ? adminroom_collection
        : chatroom_collection;
      const chatroom_doc = doc(collection_ref, selected?.id);
      const chatroom_doc_res = await getDoc(chatroom_doc);
      const chatroom_data = chatroom_doc_res.data();
      let update_delete_users = await add_remove_deletedusers(
        chatroom_data?.recentMessage,
        userids
      );
      const { deletedUsers, nonDeletedUsers } = update_delete_users;
      updated_recent_message = {
        ...chatroom_data?.recentMessage,
        deletedUsers,
        nonDeletedUsers,
      };
      // Create another batch for updating the chatroom document
      // Commit the batched updates
      await updateDoc(chatroom_doc, {
        quotesList: [],
        recentMessage: updated_recent_message,
      });
      return { status: 1, message: "Chat cleared successfully" };
    }
  } catch (err) {
    console.log(err);
    return { status: 0, message: err };
  }
};

const update_block_status = async (selectedroom, userId) => {
  try {
    const { roomID } = selectedroom;
    const q = query(chatroom_collection, where("roomID", "==", roomID));
    const querySnapshot = await getDocs(q);
    // Create a new batched write operation
    const batch = writeBatch(db);

    // Iterate over each document in the querySnapshot
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      const blockedUsers = docData.blockedUsers || []; // Get blockedUsers array or initialize empty array

      if (blockedUsers.includes(userId)) {
        // If userId is present in blockedUsers, remove it
        const updatedBlockedUsers = blockedUsers.filter(
          (user) => user !== userId
        );
        batch.update(doc.ref, { blockedUsers: updatedBlockedUsers });
      } else {
        // If userId is not present in blockedUsers, append it
        const updatedBlockedUsers = [...blockedUsers, userId];
        batch.update(doc.ref, { blockedUsers: updatedBlockedUsers });
      }
    });

    // Commit the batched write operation
    await batch.commit();
    const room_ref = query(
      chatroom_collection,
      where("roomID", "==", roomID),
      where("userId", "==", userId)
    );
    const rooms = await getDocs(room_ref);
    let room_val = { ...rooms.docs[0].data(), id: rooms.docs[0].id };
    return {
      status: 1,
      message: "Block status updated succesfully",
      data: room_val,
    };
  } catch (err) {
    console.log(err);
    return { status: 1, message: err };
  }
};

/**
 * Deleting the chatroom and messages in the chatroom by adding our userid to deltedusers and removing from nondeletedusers
 * @param {array} roomids
 * @param {array} userid
 * @returns
 */
const delete_chat_rooms = async (roomids, userids, message_roomids) => {
  try {
    let messagesids = message_roomids ? message_roomids : roomids;
    const messages_query = query(
      conversation_collection,
      where("roomID", "in", messagesids)
    );
    const messages_res = await getDocs(messages_query);
    if (messages_res?.empty) {
      return { status: 0, message: "No messages found" };
    } else {
      const commitBatch = async (batch) => {
        if (!batch.isEmpty) {
          await batch.commit();
        }
      };
      // Create a new batch for chatroom updates
      const chatroomBatch = writeBatch(db);
      await Promise.all(
        roomids.map(async (roomid) => {
          const chatroom_doc = doc(chatroom_collection, roomid);
          const chatroom_doc_res = await getDoc(chatroom_doc);
          const chatroom_data = chatroom_doc_res.data();
          let update_delete_users = await add_remove_deletedusers(
            chatroom_data,
            userids
          );
          const { deletedUsers, nonDeletedUsers } = update_delete_users;
          let updated_room = {
            ...chatroom_data,
            deletedUsers,
            nonDeletedUsers,
          };
          // Update the document in the batch
          await chatroomBatch.update(chatroom_doc, updated_room);
        })
      );
      await chatroomBatch.commit();
      const messages_batch = writeBatch(db);
      let batchCounter=0
      // await Promise.all(
        // messages_res.docs.map(async (msg) => 
          for(const msg  of messages_res.docs){
          //i need to update the deletedusers and nondeletedusers in the conversation collection
          const messageID = msg.id;
          const data_val = msg.data();
          const del_users_res = await add_remove_deletedusers(
            data_val,
            userids
          );
          let { deletedUsers, nonDeletedUsers } = del_users_res;
          // Construct the document reference for the current message
          const docRef = doc(conversation_collection, messageID);
          // Update the document in the batch
          await messages_batch.update(docRef, { deletedUsers, nonDeletedUsers });
          batchCounter++;
          if(batchCounter === MAX_BATCH_SIZE){
            await commitBatch(messages_batch);
            batchCounter =0;
            messages_batch = writeBatch(db);
          }
        }
      // )
      // );

      // Commit the batched updates
      await messages_batch.commit();
      return { status: 1, message: "User deleted successfully" };
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Deleting members form estimation list and from the group chat
 * @param {string} roomid
 * @param {array} userids
 * @returns
 */
const delete_groupchat_member = async (roomid, userids,deletedEstimations) => {
  try {
    const messages_query = query(
      conversation_collection,
      where("roomID", "==", roomid)
    );
    const messages_res = await getDocs(messages_query);
    if (messages_res?.empty) {
      return { status: 1, message: "room not exists" };
    } else {
      // await Promise.all(
      //   messages_res.docs.map(async (msg) => {
      //     //i need to update the deletedusers and nondeletedusers in the conversation collection
      //     const messageID = msg.id;
      //     const data_val = msg.data();

      //     const del_users_res = await add_remove_deletedusers(
      //       data_val,
      //       userids
      //     );
      //     let { deletedUsers, nonDeletedUsers } = del_users_res;
      //     // Construct the document reference for the current message
      //     const docRef = doc(conversation_collection, messageID);
      //     // Update the document in the batch
      //     await batch.update(docRef, { deletedUsers, nonDeletedUsers });
      //   })
      // );
      // Create a new batch for chatroom updates
      const chatroom_doc = query(
        chatroom_collection,
        where("roomID", "==", roomid)
      );
      const chatroom_doc_res = await getDocs(chatroom_doc);
      if (chatroom_doc_res.docs.length) {
        const chatroom_data = chatroom_doc_res.docs[0].data();
        const chatroom_serviceslist = await checking_selecteduserservices(chatroom_data,deletedEstimations);
        let updated_memberids = Object.keys(chatroom_serviceslist);
        // chatroom_data.memberIds.filter((id) => !userids.includes(id));
        let update_delete_users = await add_remove_deletedusers(
          chatroom_data,
          userids
        );
        const { deletedUsers, nonDeletedUsers } = update_delete_users;
        deletedUsers.filter((id)=>!updated_memberids.includes(id));
        let updated_room = {
          ...chatroom_data,
          deletedUsers,
          nonDeletedUsers:updated_memberids,
          memberIds: updated_memberids,
          userServices:chatroom_serviceslist
        };
        await updateDoc(
          doc(chatroom_collection, chatroom_doc_res.docs[0].id),
          updated_room
        );
      } else {
        return { status: 1, message: "Member not found in room" };
      }

      // await batch.commit();
      return { status: 1, message: "Messages deleted successfully" };
    }
  } catch (err) {
    console.log(err)
  }
};

/**
 * Removing estimations from selected users is estimations exists
 */
const remove_estimations_selectedusers = async()=>{
  try{

  }
  catch(err){
    console.log(err)
  }
}
/**
 * Retrieving particlar message object
 * @param {string} msgid
 * @returns object
 */
const retrieve_message = async (msgid) => {
  try {
    let msg_doc = doc(conversation_collection, msgid);
    const msg_ref = await getDoc(msg_doc);
    return { status: 1, message: { ...msg_ref.data() } };
  } catch (err) {
    console.log(err);
  }
};

/**
 * User blocked and deactivated case
 * @param {string} userid
 */
const Userblock_deactivated = async (userid) => {
  try {
    const deactivated_ref = await doc(deactivated_users_collection, userid);
    const user_ref = doc(users_collection, userid);
    await updateDoc(user_ref,{isActive:1})
    await setDoc(deactivated_ref, { userId: userid });
    return { status: 1 };
  } catch (err) {
    console.log(err);
  }
};

const Userunblock_activated = async (userid) => {
  try {
    let deactivated_ref = await doc(deactivated_users_collection, userid);
    const user_ref = doc(users_collection, userid);
    await updateDoc(user_ref,{isActive:0})
    await deleteDoc(deactivated_ref);
    return { status: 1 };
  } catch (err) {
    console.log(err);
  }
};
export {
  create_user,
  update_user,
  retrieve_user,
  retrieve_users_list,
  checking_chatroom_existence,
  checking_groupchatroom_existence,
  create_update_chatroom,
  update_receivers_room,
  create_admin_chatroom,
  create_update_groupchatroom,
  create_update_message,
  update_chatroom_message,
  getuser_unseen_messages,
  update_message_seenusers,
  getTotalMessagesCount,
  update_badge_count,
  update_archievelist,
  retrieving_media_data,
  clear_chat,
  update_block_status,
  delete_chat_rooms,
  update_groupchatroom_quoteslist,
  retrieve_message,
  delete_groupchat_member,
  update_similar_quotemessages,
  delete_estimationmembers,
  create_chatroom_connection,
  get_updated_chatroom,
  Userblock_deactivated,
  Userunblock_activated,
};
