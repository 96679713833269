import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Portfolio_Styles } from "@styles/Dashboardstyles";
import Customizedbutton from "@components/Customizedbutton";
import { style_exports } from "@exports/style_exports";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomizedAlert = ({ open, close, textOrHtml, action, data = {} }) => {
  const JSX_styles = Portfolio_Styles();
  const handleaction = () => {
    action(data);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        maxWidth={"sm"}
        className={JSX_styles.dialogPaper}
        PaperProps={{
          sx: {
            minWidth: 400,
            maxWidth: "100%",
            "::-webkit-scrollbar": { display: "none" },
            "@media screen and (min-width: 200px) and (max-width: 450px)": {
              width: "90%",
              minWidth: 290,
              maxWidth: "290px",
            },
          },
        }}
      >
        <DialogTitle textAlign={"end"}>
          {/* <HighlightOffIcon onClick={close} /> */}
          <CancelIcon
          className={JSX_styles.closeiconstyle}
          onClick={close}
        />
        </DialogTitle>
        <DialogContent>{textOrHtml}</DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            marginBottom: "1.5rem",
            gap: "1.5rem",
          }}
        >
          <Customizedbutton
            styles={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
            bgcolor={style_exports?.shade_color}
            data={"Cancel"}
            onClick={close}
          />
          <Customizedbutton
            styles={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
            data={"Ok"}
            onClick={handleaction}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomizedAlert;
