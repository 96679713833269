import React from "react";
import defaultImage from "../assets/images/defaultimagesquare.png"

const CommonImage = ({ src, ...props }) => {
  return (
    <>
      <img src={src || defaultImage} {...props} />
    </>
  );
};

export default CommonImage;
