import React from "react";

const AvatarIcon = () => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 19.8619V17.9775C20 16.9779 19.5786 16.0193 18.8284 15.3125C18.0783 14.6057 17.0609 14.2086 16 14.2086H8C6.93913 14.2086 5.92172 14.6057 5.17157 15.3125C4.42143 16.0193 4 16.9779 4 17.9775V19.8619"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 10.4398C14.2091 10.4398 16 8.75242 16 6.67095C16 4.58947 14.2091 2.9021 12 2.9021C9.79086 2.9021 8 4.58947 8 6.67095C8 8.75242 9.79086 10.4398 12 10.4398Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default AvatarIcon;
