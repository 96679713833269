import { createSlice } from "@reduxjs/toolkit";
/**
 * @param {object}-data
 * @param {integer}-type=>{
 * 1->Edit
 * 2->delete
 * 3->makepublic
 * 4->Again coming to  upload gallary
 * }
 * @param {integer}-component type
 * Slice is used for edit,delete, makepublic in portfolio screens
 */
const initialState ={data:{},operation_type:0,component_type:0,public_status:0};

const Portfoliocrudslice = createSlice(
    {
        name:'portfoliocrud',
        initialState,
        reducers:{
            portfolio_operations(state,action){
                state.data = action.payload[0]
                state.operation_type=action.payload[1]
                state.component_type=action.payload[2]
                state.public_status=action.payload[3]
            },
            portfoliooperations_clear(state,action){
                state.data={}
                state.operation_type=0
                state.component_type=0
                state.public_status=0
            }
        }
    }
) 
export const PortfoliocrudActions= Portfoliocrudslice.actions;
export default Portfoliocrudslice;