import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Termsconditionstext } from "@styles/Terms&conditions";
import Privacytermshoc from "./Hocs/Privacytermshoc";
import { scrollTop } from "../helpers/Helperfunctions";

const Privacypolicy = ({ data }) => {
  const classes = Termsconditionstext();
  const [val,setVal] = useState("Data")
  useEffect(() => {
    scrollTop()
  }, []);
  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginBottom: "2rem" }}>
        {data&&
          Object.keys(data).length > 0 && (
            <div
              dangerouslySetInnerHTML={{ __html: data?.content }}
            />
          )}{" "}
      </Container>
    </>
  );
};
// Enhance MyComponent with the Privacytermshoc HOC
const Privacypolicywithdata = Privacytermshoc(Privacypolicy);
export default Privacypolicywithdata;
