import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Customizedinput from "@components/Customizedinput";
import { InputAdornment } from "@mui/material";
import calender from "@images/calender.svg";
import CommonImage from "@components/CommonImage";

const Customizeddate = ({ placeholder,bg_color, ...otherprops }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          defaultCalendarMonth={otherprops?.minDate}
          sx={{
            "& .MuiPickersToolbar-penIconButton": {
              display: "none",
            },   
          }}
          closeOnSelect
          {...otherprops}
          renderInput={(params) => (
            <Customizedinput
              id="dateInputTag"
              {...params}
              fullWidth
              // bg_color
              bg_color={bg_color}
              placeholder={placeholder}
              autoComplete="off"
              size="medium"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start" style={{cursor:'pointer'}}>
                    <CommonImage src={calender} alt="icon" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default Customizeddate;
