import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Customizedbutton from "@components/Customizedbutton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Header_styles } from "@styles/Header_styles";
import { useDispatch, useSelector } from "react-redux";
import { style_exports } from "@exports/style_exports";
import imageset1 from "@images/imageset1.png";
import imageset2 from "@images/imageset2.png";
import imageset3 from "@images/imageset3.png";
import imageset4 from "@images/imageset3.png";
import MenuIcon from "@mui/icons-material/Menu";
import AvatarIcon from "@components/AvatarIcon";
import CloseIcon from "@mui/icons-material/Close";
import Usersidebar from "@parts/Usersidebar";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { defaultcountry_code } from "@constants/Userconstants";
import Countrycode from "@models/Countrycode";
import { StorageClient } from "@helpers/Helperfunctions";
import { completeprofile_Actions } from "@store/Completeprofileslice";
import { mocaHeaderNavOptions } from "../constants/Userconstants";
import { Envexports } from "../../exports/Envexports";
import CommonImage from "@components/CommonImage";

const Mocaheader = () => {
  const [navCount, setNavCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const header_styles = Header_styles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const large_screen = useMediaQuery("(min-width:1920px)");
  // Redux data
  const login_status = useSelector(
    (state) => state.authentication.login_status
  );
  const profile_data = useSelector(
    (state) => state.complete_profile?.profile_data
  );
  const user_data = useSelector((state) => state.authentication.data);
  const user_type = useSelector((state) => state.authentication.user_type);
  const [countrycode_model, setCountrycode_model] = useState(false);
  const [application_currency, setApplication_currency] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dashboardDrawerOpen, setDashboardDrawerOpen] = useState(false);
  // getting country codes
  const handlecountry_selection = async (country) => {
    window.location.reload();
    StorageClient.setItem("currency", country?.currency ?? "");
    StorageClient.setItem("currencycountry", country);
    setApplication_currency(country);
    // dispatch(completeprofile_Actions.curency_update(country));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1172 && window.innerWidth < 1311) {
        setNavCount(2);
      } else if (window.innerWidth > 1020 && window.innerWidth < 1173) {
        setNavCount(3);
      } else if (window.innerWidth > 899 && window.innerWidth < 1021) {
        setNavCount(4);
      } else {
        setNavCount(0);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      handleResize();
      window.addEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    let currencycountry = StorageClient.getItem("currencycountry");
    if (currencycountry !== undefined) {
      setApplication_currency(currencycountry);
      dispatch(completeprofile_Actions.curency_update(currencycountry));
    } else {
      setApplication_currency(defaultcountry_code);
      dispatch(completeprofile_Actions.curency_update(defaultcountry_code));
    }
  }, []);
  useEffect(()=>{
    const token = StorageClient.getItem("token");
    if(user_data?.id !== profile_data?.userId && !token && location?.pathname !== "/mocalogin") {
      StorageClient.clear();
      localStorage.removeItem("persist:root");
      window.location.replace("/");
    }
  },[user_data, profile_data])
  return (
    <>
      {countrycode_model && (
        <Countrycode
          open={countrycode_model}
          close={() => setCountrycode_model(false)}
          currency={true}
          countrycode_selection={handlecountry_selection}
        />
      )}
      <div
        style={{
          backgroundColor: "#fff",
          height: "5.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xl">
          {/* large screen header */}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item md={2}>
              <NavLink
                to="/home"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/home");
                }}
              >
                {/* <CommonImage src={logo} alt="moca logo" width="40%" height="auto" /> */}
                <CommonImage
                  src={imageset1}
                  srcSet={`${imageset1} 640w, ${imageset2} 1280w, ${imageset3} 1920w,`}
                  sizes="
                  (max-width: 1920px) 25vw,
                  (max-width: 1280px) 100vw,
                  (max-width: 640px) 100vw,
                  320px"
                  alt="Responsive Image"
                />
              </NavLink>
            </Grid>
            <Grid item md alignItems={"center"} mt={2}>
              <Stack direction="row" spacing={large_screen ? 6 : 4}>
                {mocaHeaderNavOptions
                  ?.slice(0, mocaHeaderNavOptions?.length - navCount)
                  ?.map((navOptions) => {
                    const shouldRenderNavLink =
                      navOptions?.label !== "Pricing" ||
                      (navOptions?.label === "Pricing" &&
                        !profile_data?.userDetails?.profileStatus &&
                        user_type === 0);
                    if (shouldRenderNavLink) {
                      return (
                        <>
                          {
                            <Grid item key={navOptions?.label}>
                              <NavLink
                                to={navOptions?.url}
                                // exact
                                className={({ isActive }) =>
                                  isActive
                                    ? header_styles.active
                                    : header_styles.inactive
                                }
                              >
                                {navOptions?.label}
                              </NavLink>
                            </Grid>
                          }
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* dropdown */}
                {navCount ? (
                  <Grid item>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // className={header_styles.inactive}
                      sx={{
                        padding: 0,
                        textTransform: "unset",
                        minWidth: "auto",
                        fontWeight: "700 !important",
                        color: "#333333",
                        "& .MuiButton-icon": {
                          marginLeft: "0px",
                        },
                      }}
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownSharpIcon />}
                    >
                      More
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {mocaHeaderNavOptions
                        ?.slice(
                          mocaHeaderNavOptions?.length - navCount,
                          mocaHeaderNavOptions?.length
                        )
                        ?.map((navOptions) => {
                          const shouldRenderNavLink =
                            navOptions?.label !== "Pricing" ||
                            (navOptions?.label === "Pricing" &&
                              !profile_data?.userDetails?.profileStatus &&
                              user_type === 0);
                          if (shouldRenderNavLink) {
                            return (
                              <MenuItem onClick={handleClose} key={navOptions?.label}>
                                <NavLink
                                  to={navOptions?.url}
                                  className={({ isActive }) =>
                                    isActive
                                      ? header_styles.active
                                      : header_styles.inactive
                                  }
                                >
                                  {navOptions?.label}
                                </NavLink>
                              </MenuItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Menu>
                  </Grid>
                ) : null}
              </Stack>
            </Grid>
            {/* Currency */}
            {profile_data?.userDetails?.profileStatus && user_type === 0 && (
              <Grid
                item
                md={"auto"}
                width={"100px !important"}
                textAlign={"center"}
                container
                direction={"column"}
                onClick={() => setCountrycode_model(true)}
              >
                <Grid
                  item
                  sx={{ fontSize: "12px", color: "#000000", opacity: "50%" }}
                >
                  Currency
                </Grid>
                <Grid
                  item
                  container
                  sx={{ fontSize: "12px", cursor: "pointer" }}
                  justifyContent={"center"}
                >
                  <Grid item md={3}>
                    {/* {application_currency.emoji} */}
                    <CommonImage
                      src={`${Envexports.flagsbaseurl}${application_currency.flag}`}
                      width="60%"
                      height="40%"
                      alt="flag"
                    />
                    {/* <CommonImage src={`${Envexports.flagsbaseurl}${item?.flag}`} width={"60%"} height="40%"/> */}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {application_currency?.countryName
                      ? application_currency.countryName.toLocaleUpperCase()
                      : ""}
                  </Grid>
                  <Grid item md={1}>
                    <KeyboardArrowDownSharpIcon
                      sx={{
                        color: style_exports?.skyblue_color,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={"auto"} container justifyContent={"flex-end"}>
              {login_status ? (
                <NavLink to="/dashboard">
                  <Button
                    startIcon={
                      <Avatar
                        variant="rounded"
                        sx={{ width: 28, height: 28, borderRadius: "0.5rem" }}
                        src={profile_data?.userDetails?.profileImage ?? null}
                      ></Avatar>
                    }
                    sx={{
                      backgroundColor: `${style_exports.grey_color}!important`,
                      color: `${style_exports.shade_color}`,
                      fontWeight: "600",
                      width: "150px",
                      height: "40px",
                      borderRadius: "0.5rem",
                      textTransform: "none",
                      justifyContent: "start",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {profile_data?.plan?.id ? "My Profile" : "Demo Profile"}
                  </Button>
                </NavLink>
              ) : (
                <NavLink
                  to={{
                    pathname: "/mocalogin",
                    state: { login: 1 }, // Set your desired state here
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Customizedbutton
                    data="Login / Signup"
                    size={"lg"}
                    styles={{ textDecoration: "none" }}
                  />
                </NavLink>
              )}
            </Grid>
          </Grid>
          {/* small screen header */}
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <Grid item>
              <NavLink
                to="/home"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/home");
                }}
              >
                <CommonImage
                  src={imageset4}
                  style={{ width: "85px" }}
                  alt="Responsive Image"
                />
              </NavLink>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} gap={2.5}>
                {!login_status ? (
                  <Grid item>
                    <NavLink to="/mocalogin" style={{ textDecoration: "none" }}>
                      <Customizedbutton
                        data="Login / Signup"
                        // swidth="150px"
                        styles={{ textDecoration: "none", fontSize: "0.7rem" }}
                        onClick={() => navigate("/mocalogin")}
                      />
                    </NavLink>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item>
                  <IconButton
                    className={header_styles.menuIconStyles}
                    onClick={() => setDrawerOpen(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  {/* side bar */}
                  <Drawer
                    anchor="right"
                    size="sm"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                  >
                    <Box sx={{ width: "300px" }}>
                      <Grid container justifyContent={"flex-end"}>
                        <IconButton onClick={() => setDrawerOpen(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      {/* nav options */}
                      <List sx={{ "& > li": { marginBottom: "30px" } }}>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              to="/home"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              Home
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              to="/allcategories"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              All Categories
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              to="/findwork"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              Find Work
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              to="/postrequest"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              Post A Request
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              to="/castingcalls"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              Casting Calls
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton sx={{ padding: "0px" }}>
                            <NavLink
                              exact
                              to="/contactus"
                              className={({ isActive }) =>
                                isActive
                                  ? header_styles.resActive
                                  : header_styles.resInactive
                              }
                            >
                              Contact Us
                            </NavLink>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Box>
                  </Drawer>
                </Grid>
                {login_status ? (
                  <Grid item>
                    <IconButton
                      className={header_styles.menuIconStyles}
                      onClick={() => setDashboardDrawerOpen(true)}
                    >
                      <AvatarIcon />
                    </IconButton>
                    <Drawer
                      anchor="left"
                      size="sm"
                      open={dashboardDrawerOpen}
                      onClose={() => setDashboardDrawerOpen(false)}
                    >
                      <Box sx={{ width: "300px", paddingLeft: "1rem" }}>
                        <Grid container justifyContent={"flex-end"}>
                          <IconButton
                            onClick={() => setDashboardDrawerOpen(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                        {/* nav options */}
                        <Usersidebar
                          close={() => setDashboardDrawerOpen(false)}
                        />
                      </Box>
                    </Drawer>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Mocaheader;
