import { Dialog, Grid, Stack, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import Customizedinput from "@components/Customizedinput";
import Customizedbutton from "@components/Customizedbutton";
import {
  passwordchecking,
  pwd_cnfmpwd_validation,
} from "@validations/Commonvalidations";
import { useSelector } from "react-redux";
import Hideeye from "@components/Hideeye";
import Vieweye from "@components/Vieweye";
import { resetpwd } from "../services/Userservices";
import { customEnqueueSnackbar } from "@constants/Jsxconstants";
import CancelIcon from "@mui/icons-material/Cancel";

const Resetpwd = (props) => {
  const { open, close } = props;
  const user_data = useSelector((state) => state.authentication.data);
  // states initialization
  const [passwords, setPasswords] = useState({
    new_pwd: "",
    cnfm_pwd: "",
    new_pwd_status: false,
    cnfm_pwd_status: false,
  });
  const handleinputchange = (event) => {
    const { name, value } = event.target;
    setPasswords((prev) => ({ ...prev, [name]: value.trim() }));
  };
  const handleresetpwd = async () => {
    try {
      if (passwordchecking(passwords?.new_pwd, "Newpassword", 1)) return;
      if (passwordchecking(passwords?.cnfm_pwd, "Confirm password", 1)) return;
      if (pwd_cnfmpwd_validation(passwords?.new_pwd, passwords?.cnfm_pwd))
        return;
      const reset_req = {
        userId: user_data?.id,
        password: passwords?.new_pwd,
      };
      const resetpwd_res = await resetpwd(reset_req);
      if (resetpwd_res?.status) {
        customEnqueueSnackbar(resetpwd_res?.message, { variant: "success" });
        close();
      }
      else{
        customEnqueueSnackbar(resetpwd_res?.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          position: "fixed",
          top: "30%",
          borderRadius: "25px",
          background: "#FFFFFFCC",
          m: 0,
          minHeight: 270,
          maxHeight: 400,
          width: 400,
          maxWidth: 500,
          padding: "1rem",
          backdropFilter: "blur(15px)",
          "@media screen and (min-width: 200px) and (max-width: 550px)": {
            width: "80%",
            maxWidth: "80%",
          },
        },
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item container>
          <Grid
            item
            md={12}
            textAlign={"center"}
            sx={{ fontWeight: "700", fontSize: "1.5rem" }}
          >
            Reset Password
          </Grid>
          <CancelIcon
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#04BEF8",
            }}
            // className={JSX_styles.closeiconstyle}
            onClick={close}
          />
        </Grid>
        <Grid item mt={2}>
          <Stack spacing={1}>
            <Grid item>New Password</Grid>
            <Grid item>
              <Customizedinput
                size={"medium"}
                decapitalize={true}
                value={passwords?.new_pwd}
                onChange={handleinputchange}
                name="new_pwd"
                placeholder="Enter your password"
                fullWidth
                type={passwords?.new_pwd_status ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setPasswords((prev) => ({
                          ...prev,
                          new_pwd_status: !prev.new_pwd_status,
                        }))
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {passwords?.new_pwd_status ? <Vieweye /> : <Hideeye />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid item>Confirm Password</Grid>
            <Grid item>
              <Customizedinput
                size={"medium"}
                decapitalize={true}
                value={passwords?.cnfm_pwd}
                onChange={handleinputchange}
                name="cnfm_pwd"
                placeholder="ReEnter your password"
                fullWidth
                type={passwords?.cnfm_pwd_status ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setPasswords((prev) => ({
                          ...prev,
                          cnfm_pwd_status: !prev.cnfm_pwd_status,
                        }))
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {passwords?.cnfm_pwd_status ? <Vieweye /> : <Hideeye />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item container justifyContent={"center"} my={1} textAlign={"center"}>
          <Customizedbutton
            // swidth={"10rem"}
            size={"xxl"}
            data="Reset"
            onClick={handleresetpwd}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Resetpwd;
