import {
  Skeleton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  cardContainer: {
    borderRadius: "1rem",
    backgroundColor: "#F2F2F2",
    boxShadow: "none",
  },
  cardHeader: {
    padding: "0.8rem 1.5rem",
    borderRadius: "1rem",
  },
  cardBody: {
    padding: "1rem 1.5rem",
  },
  cardFooter: {
    padding: "1rem 1.5rem",
    paddingTop: "0rem",
  },
  profileContainer: {
    "&.MuiListItem-root": {
      padding: "0px",
    },
    "& .MuiAvatar-root": {
      width: "45px",
      height: "45px",
    },
    "& span.MuiTypography-root": {
      fontSize: 0,
    },
    "& .MuiRating-root label": {
      fontSize: "1.2rem",
    },
  },
});

export const FindWorkCardSkeleton = () => {
  const styles = useStyles();

  return (
    <Card className={styles.cardContainer}>
      <CardHeader
        className={styles.cardHeader}
        avatar={
          <Grid container columnGap={2} direction={"column"}>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={120} height={30} />
          </Grid>
        }
        action={
          <Grid container gap={1} alignItems={"center"}>
            <Skeleton variant="text" width={60} height={20} />
            <Skeleton variant="circular" width={25} height={25} />
          </Grid>
        }
      />
      <CardContent className={styles.cardBody}>
        <Grid container gap={1.5}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={80} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={80} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={80} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={120} height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width={100} height={20} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={styles.cardFooter}>
        <ListItem className={styles.profileContainer}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={45} height={45} />
          </ListItemAvatar>
          <ListItemText
            primary={<Skeleton variant="text" width={80} height={20} />}
            secondary={<Skeleton variant="text" width={100} height={15} />}
          />
        </ListItem>
      </CardActions>
    </Card>
  );
};
