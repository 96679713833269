import { Skeleton, Grid, Stack } from "@mui/material";
import React from "react";

const PricingCardSkeleton = () => {
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        marginTop: "3rem",
        borderRadius: "1rem",
        padding: "1rem",
      }}
    >
      <Grid container spacing={2}>
        {/* Main Image Skeleton */}
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Grid>

        {/* Plan Info Skeleton */}
        <Grid item xs={12}>
          <Skeleton variant="text" width="60%" height={40} />
        </Grid>

        {/* Plan Details Skeleton */}
        <Grid item xs={12}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="90%" />
        </Grid>

        {/* Button Skeleton */}
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PricingCardSkeleton;
