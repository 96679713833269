import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
export const Header_styles = makeStyles({
  inactive: {
    textDecoration: "none!important",
    color: style_exports.shade_color,
    fontWeight: 400,
  },
  active: {
    textDecoration: "none!important",
    color: "#333333",
    fontWeight: "700",
    display: 'inline-block',
    padding: '0 10px 10px 10px', // Adjust the distance from the text as needed
    textAlign: 'center',
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        height:'5px',
        width:'5px',
        bottom: 0,
        backgroundColor:style_exports.skyblue_color,
        borderRadius:'60%',
        left: '50%',
        transform: 'translateX(-50%)',
      },
  },
  menuIconStyles: {
    width:'3rem',
    height:'3rem',
    backgroundColor:`${style_exports.btn_bgcolor} !important`,
    color:'#fff !important'
  },
  resInactive: {
    textDecoration: "none!important",
    color: style_exports.shade_color,
    fontWeight: 400,
    width:'100%',
    padding:'0.5rem 1rem',
  },
  resActive: {
    textDecoration: "none!important",
    color: style_exports.skyblue_color,
    fontWeight: "700",
    display: 'inline-block',
    width:'100%',
    padding:'0.5rem 1rem',
  },
});
