// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Envexports } from "@exports/Envexports";
import { getStorage } from "firebase/storage";
const Firebaseconfig = {
  apiKey: Envexports.firebase_apikey,
  authDomain: Envexports.firebase_authdomain,
  projectId: Envexports.firebase_projectid,
  storageBucket: Envexports.firebase_storagebucket,
  messagingSenderId: Envexports.firebase_messagesenderid,
  appId: Envexports.firebase_appid,
  measurementId: Envexports.firebase_measurementid,
  
};
// Initialize Firebase
const app = initializeApp(Firebaseconfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
